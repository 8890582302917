<template>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="single-cards-item">
                <div class="single-product-text">
                    <div class="col-sm-12" style="padding:0 0 10px 0;">
                        <div class="single-review-st-hd col-sm-12 center">
                            <h2>Creación de preguntas</h2>
                        </div>
                        <div class="col-sm-12" style="border:1px solid purple;margin-bottom:20px"></div>
                        <div class="col-sm-12" style="padding:0;margin-bottom:10px;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                <label><span style="color:red">*</span>Tipo de pregunta</label>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-select-list">
                                    <v-select v-model="createExam.type_question" :multiple="false" :options="typeP" placeholder="Seleccione una opción"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:15px">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                    <label><span style="color:red">*</span>Preguna N°:</label>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-select-list">
                                        <input class="form-control" type="number" v-model="createExam.questions_order_selected" placeholder="Numero del 1 al .."/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="padding:0;margin-bottom:10px">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                <label class=""><span style="color:red">*</span>Contenido de la pregunta:</label>
                            </div>
                            <div class="col-sm-12">
                                <vue-editor
                                    id="vue_editor"
                                    v-model="createExam.content"
                                    useCustomImageHandler
                                    :editorToolbar="customToolbar"
                                    :editorOptions="editorSettings"
                                    @image-added="handleImageAdded">
                                </vue-editor>
                            </div>
                        </div>

                        <div class="col-sm-12" style="padding:0;margin-bottom:10px;">
                            <div class="col-sm-12">
                                <template v-if="`${createExam.type_question.code}` === '1'">
                                    <div @click="add_question" class="no_sele" style="cursor:pointer;border-radius:5px;border:1px solid #37BC98;width:160px;float:right;margin-bottom:10px;padding:3px">
                                        <span style="font-weight:400"><i class="material-icons" style="font-size:17px;float:left;padding-top:2px">add</i>Agregar respuestas</span>
                                    </div>
                                    <div class="col-sm-12" style="padding:0;margin-bottom:10px">
                                        <div class="col-sm-3" style="padding-left:0">
                                            <span style="font-weight:500">Valor</span>
                                        </div>
                                        <div class="col-sm-9" style="padding-left:0">
                                            <span style="font-weight:500">Respuesta</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12" style="padding:0">
                                        <template v-for="(answer_opt,inx_opt) in options">
                                            <div class="col-sm-12" style="padding:0;margin-bottom:10px" :key="inx_opt">
                                                <div class="col-sm-3" style="padding-left:0">
                                                    <input type="number" class="form-control center" v-model="answer_opt.value" placeholder="0.0">
                                                </div>
                                                <div class="col-sm-9" style="padding:0;position:relative">
                                                    <input type="text" class="form-control" v-model="answer_opt.answer" :placeholder="`Opción de respuesta ${inx_opt+1}`" style="padding-right:25px"/>
                                                    <i v-if="inx_opt > 1" class="material-icons no_sele" style="position:absolute;top:11px;right:5px;font-size:17px;color:red;cursor:pointer;" @click="clearOpt(inx_opt)">clear</i>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>

                                <template v-if="`${createExam.type_question.code}` === '2'">
                                    <div @click="add_question" class="no_sele" style="cursor:pointer;border-radius:5px;border:1px solid #37BC98;width:160px;float:right;margin-bottom:10px;padding:3px">
                                        <span style="font-weight:400"><i class="material-icons" style="font-size:17px;float:left;padding-top:2px">add</i>Agregar respuestas</span>
                                    </div>
                                    <div class="col-sm-12" style="padding:0;margin-bottom:10px">
                                        <div class="col-sm-3" style="padding-left:0">
                                            <span style="font-weight:500">Valor</span>
                                        </div>
                                        <div class="col-sm-9" style="padding-left:0">
                                            <span style="font-weight:500">Respuesta</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12" style="padding:0">
                                        <template v-for="(answer_opt,inx_opt) in options">
                                            <div :key="inx_opt" class="col-sm-12" style="padding:0;margin-bottom:10px">
                                                <div class="col-sm-3" style="padding-left:0">
                                                    <input type="number" class="form-control center" v-model="answer_opt.value" placeholder="0.0">
                                                </div>
                                                <div class="col-sm-9" style="padding:0;position:relative">
                                                    <input type="text" class="form-control" v-model="answer_opt.answer" :placeholder="`Opción de respuesta ${inx_opt+1}`" style="padding-right:25px"/>
                                                    <i v-if="inx_opt > 1" class="material-icons no_sele" style="position:absolute;top:11px;right:5px;font-size:17px;color:red;cursor:pointer" @click="clearOpt(inx_opt)">clear</i>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>

                                <template v-if="`${createExam.type_question.code}` === '5'">
                                    <div class="col-sm-12" style="padding:0;margin-bottom:10px">
                                        <div class="col-sm-3" style="padding-left:0">
                                            <span style="font-weight:500">Valor</span>
                                        </div>
                                        <div class="col-sm-9" style="padding-left:0">
                                            <span style="font-weight:500">Respuesta</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12" style="padding:0">
                                        <template v-for="(answer_opt,inx_opt) in options">
                                            <div :key="inx_opt" class="col-sm-12" style="padding:0;margin-bottom:10px">
                                                <div class="col-sm-3" style="padding-left:0">
                                                    <input type="number" class="form-control center" v-model="answer_opt.value" placeholder="0.0">
                                                </div>
                                                <div class="col-sm-9" style="padding:0;position:relative">
                                                    <input type="text" class="form-control" :value="answer_opt.answer" disabled style="padding-right:25px"/>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>

                            </div>
                        </div>
                        <div class="col-sm-12">
                            <button @click="active ? createQuestions() : ''" :class="`btn ${active ? 'success' : 'default'} loginbtn`" style="float:right;width:200px;margin-left:5px;position:sticky;bottom:0">Registrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="single-review-st-item res-mg-t-30 table-mg-t-pro-n">
                <div class="single-review-st-hd col-sm-12 center">
                    <h2>Previsualización del examen</h2>
                </div>

                <div class="col-sm-12" style="border:1px solid purple;margin-bottom:10px"></div>
                
                <div class="col-sm-12" style="padding:0;margin-bottom:10px">
                    <div class="col-sm-12 alertSuccess" style="padding:10px;font-size:16px">
                        Usted esta editando este examen
                    </div>
                </div>
                <div class="col-sm-12" style="border:1px solid #DBDBDB;border-radius:5px;padding:0.75rem;margin-bottom:10px">
                    <div v-if="examenoB.file" :style="`background-image:url(${examenoB.file});background-repeat:no-repeat;background-position:center;background-size:cover;width:100%;height:50px;float:left;margin-bottom:10px;position:relative;border:1px solid #DBDBDB;border-radius:5px;`">
                    </div>
                    <div style="width:100%;float:left;border-bottom:1px solid #DBDBDB;padding-bottom:10px">
                        <div style="width:40%;float:left;padding:5px 0">
                            <b style="font-size:20px;text-transform:uppercase;padding:0;width:100%;float:left;">{{examenoB.title}}</b>

                        </div>
                        <div style="width:60%;float:left;">
                            <span style="font-size:15px;padding:0;text-align:right;color:#0874fa;width:100%;float:left;"><i class="material-icons" style="font-size:15px;margin-right:2px;">date_range</i>Inicio: {{examenoB.fechainicio.replace('00:00:00','')}} - Fin: {{examenoB.fechafin.replace('00:00:00','')}}</span>
                            <span style="font-size:15px;padding:0;text-align:right;color:#858585;width:100%;float:left;"><i class="material-icons" style="font-size:15px;margin-right:2px;">schedule</i>Duracion: {{examenoB.duracion}}</span>
                            <span style="font-size:15px;padding:0;text-align:right;color:#858585;width:100%;float:left;">Intentos: {{examenoB.intentos}}</span>
                        </div>
                    </div>

                    <div class="col-sm-12" style="padding:0;position:relative">
                        <div class="col-md-12 ql-editor ql-blank" v-html="examenoB.descripcion" style="padding:0;margin-top:15px;margin-bottom:10px;min-height:auto !important;"></div>
                    </div>
                </div>

                <div class="col-sm-12" style="padding:0">
                    <button @click="$emit('finishQuestions')":class="`btn success loginbtn`" style="float:right;width:200px;margin-left:5px;position:sticky;bottom:0">Terminar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .text_align_left{
        text-align: left;
    }
</style>

<script>
import { VueEditor } from "vue2-editor";
import Quill from "quill";
import ImageResize from 'quill-image-resize-module'
import { ImageDrop } from 'quill-image-drop-module'
import Vuex from '@/store'
import jwt_decode from "jwt-decode";
Quill.register('modules/imageDrop', ImageDrop)
Quill.register("modules/imageResize", ImageResize);
export default {
    props:['st','examenoB'],
    data(){
        return{
            question_order:[
                {
                    code:true,
                    label:'Si'
                },
                {
                    code:false,
                    label:'No'
                }
            ],
            exam_preview:[],
            createExam:{
                type_question:'',
                content:''
            },
            type_question_select:[
                {
                    code:1,
                    label: 'Multiple con una respuesta correcta'
                },
                {
                    code:2,
                    label: 'Multiple con multiple respuesta correcta'
                },
                {
                    code:5,
                    label: 'Verdadero o falso'
                }
            ],
            options:[
                {
                    value:'',
                    answer:''
                },
                {
                    value:'',
                    answer:''
                }
            ],
            editorSettings: {
                modules: {
                    imageDrop: false,
                    imageResize: {
                        modules: [ 'Resize', 'DisplaySize' ]
                    },
                }
            },
            customToolbar: [
               [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link","video"]
            ],
            typeP:[],
            active:false,
        }
    },
    components:{
        VueEditor,
        ImageDrop
    },
    watch:{
        examenoB(){
            this.verify_exam_id();
        },
        'createExam.type_question':{
            handler(){
                if(`${this.createExam.type_question.code}` === '1' || `${this.createExam.type_question.code}` === '2'){
                    this.options = [];
                    this.options = [
                        {
                            value:'',
                            answer:''
                        },
                        {
                            value:'',
                            answer:''
                        }
                    ]
                }else if(`${this.createExam.type_question.code}` === '5'){
                    this.options = [];
                    this.options = [
                        {
                            value:'',
                            answer:'Verdadero'
                        },
                        {
                            value:'',
                            answer:'Falso'
                        }
                    ]
                }
            },deep:true
        },
        createExam:{
            handler(){
                let dt = this.createExam;

                if(dt.content && dt.questions_order_selected && dt.type_question){

                    if(`${dt.type_question.code}` === '1' || `${dt.type_question.code}` === '2' || `${dt.type_question.code}` === '5'){
                        this.active = false
                    }else{
                        this.active = true
                    }
                }else{
                    this.active = false
                }

                //console.log(dt)
            },deep:true
        },
        options:{
            handler(){
                for(const i in this.options){
                    //console.log(this.options[i])
                    if(this.options[i].value && this.options[i].answer){
                        this.active = true
                    }else{
                        this.active = false
                    }
                }
            },deep:true
        }
    },
    methods:{
        createQuestions(){
            let id = Object.keys(this.$route.query)[0];
            this.$emit('createQuestions',{body:this.createExam,options:this.options,id_exam:id})
        },
        select_question_m_m(inx,data){
            for(const i in this.options){
                if(`${i}` === `${inx}`){
                    if(this.options[i].value){
                        this.options[i].value = false
                    }else{
                        this.options[i].value = true
                    }
                }
            }
        },
        select_question(inx,data){
            for(const i in this.options){
                if(`${i}` === `${inx}`){
                    this.options[i].value = true
                }else{
                    this.options[i].value = false
                }
            }
            //console.log(this.options[inx])
        },
        clearOpt(inx){
            this.options.splice(inx,1)

            let arr = this.options.slice();
            this.options = [];
            this.options = arr;
        },
        handleImageAdded(file, Editor, cursorLocation, resetUploader){
            //this.$emit('handleImageAdded',file, Editor, cursorLocation, resetUploader)
        },
        add_question(){
            this.options.push({
                value:'',
                answer:''
            })
        },
        verify_exam_id(){
            console.log(this.examenoB)
        },
        async returnTypeAnswers(){
            let response = await Vuex.dispatch('goToBackend',{
                route:'/academic/typePre',
                method:'GET',
                identify:'typePre',
                data: ''
            })

            let dt = jwt_decode(response.data).sub
            this.typeP = []

            for(const i in dt){
                this.typeP.push({
                    code:dt[i].aetippre_id,
                    label:dt[i].aetippre_descripcion
                })
            }
        }
    },
    mounted(){
        this.verify_exam_id()
    },
    beforeMount(){
        this.returnTypeAnswers();
    }
}
</script>