<template>
    <div class="col-sm-12 container-fluid">
        <template v-if="!addQuestiosByExam">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="single-cards-item">
                        <div style="padding:20px 10px;width:100%;float:left;">
                            <div class="col-sm-12" style="padding:0 0 10px 0;">
                                <div class="single-review-st-hd col-sm-12 center">
                                    <h2>Creación de examenes</h2>
                                </div>
                                <div class="col-sm-12" style="border:1px solid purple;margin-bottom:20px"></div>
                                <template v-if="!loadRegister">

                                    <div class="col-sm-12" style="margin-bottom:15px">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                                <label><span style="color:red">*</span>Nombre del examen</label>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <input type="text" class="form-control" placeholder="Nombre del examen" v-model="createExam.name_exam"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:15px">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                                <label><span style="color:red">*</span>Grupo</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-select-list">
                                                    <v-select v-model="createExam.selected_group" :multiple="true" :options="obj" placeholder="Seleccione una opción"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:15px">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                                <label><span style="color:red">*</span>¿Preguntas en orden?</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-select-list">
                                                    <v-select v-model="createExam.questions_order_selected" :multiple="false" :options="question_order" placeholder="Seleccione una opción"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="padding:0;margin-bottom:10px">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                            <label class="">Descripción del examen:</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <vue-editor
                                                id="vue_editor"
                                                v-model="createExam.contenido"
                                                useCustomImageHandler
                                                :editorToolbar="customToolbar"
                                                :editorOptions="editorSettings">
                                            </vue-editor>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:15px">

                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                                <label><span style="color:red">*</span>Tipo de intento</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="form-select-list">
                                                    <v-select v-model="createExam.selected_typeIntentos" :multiple="false" :options="intentos" placeholder="Seleccione una opción"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:15px">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left">
                                                <label><span style="color:red">*</span>Número de intentos</label>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <input type="number" class="form-control" placeholder="Número de intentos" v-model="createExam.num_try"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:10px">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_align_left" style="padding:0">
                                            <label>Imagen del examen</label>
                                        </div>
                                        <div v-if="!images_array" @click="activar_input" :class="`dropzone${drag ? ' active' : ''}`">
                                            <div @dragleave="exit_div($event)" @drop="dropHandler($event)" @dragover="dragOverHandler($event)" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:100"></div>
                                            <input ref="file_input" @change="fileChange($event)" type="file" accept="image/*" style="display:none">
                                            <span style="position:absolute;top:50%;left:50%;position:absolute;transform:translate(-50%,-50%);color: #2C7696;font-weight:600;text-align:center;">Arrastra y suelta la imagen que deseas colocar o da click en el recuadro</span>
                                        </div>
                                        <div v-else :style="`background-image:url(${images_array});background-repeat:no-repeat;background-position:center;background-size:cover;width:100%;height:150px;float:left;margin-bottom:10px;position:relative;border:1px solid #DBDBDB;border-radius:5px;`">
                                            <div @click="images_array = '';createExam.file = '';" class="z-depth-1 no_sele" style="cursor:pointer;border-radius:100px;height:40px;width:40px;position:absolute;top:15px;right:15px;background-color:#fff;padding:8px">
                                                <span class="material-icons" style="color:red">clear</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:10px;">
                                        <div class="row">
                                            <div class="col-sm-12 text_align_left">
                                                <label><span style="color:red">*</span>Fecha de inicio</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <date-picker valueType="format" type="datetime" style="width:100%;height:40px;" v-model="createExam.date_init" placeholder="Fecha de inicio"></date-picker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:10px;">
                                        <div class="row">
                                            <div class="col-sm-12 text_align_left">
                                                <label><span style="color:red">*</span>Fecha de finalización</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <date-picker valueType="format" type="datetime" style="width:100%;height:40px;" v-model="createExam.date_finish" placeholder="Fecha de finalización"></date-picker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12" style="margin-bottom:10px;">
                                        <div class="row">
                                            <div class="col-sm-12 text_align_left">
                                                <label><span style="color:red">*</span>Duración del examen</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <date-picker v-model="createExam.time" :hour-options="[0,1,2,3,4,5,6,7,8,9,10,11,12]" valueType="format" style="width:100%;height:40px;" placeholder="Duración del examen" type="time"></date-picker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <button :class="`btn ${active ? 'success' : 'default'} loginbtn`" @click="active ? sendExam() : ''" style="float:right;width:200px;margin-left:5px;position:sticky;bottom:0">Registrar Examen</button>
                                    </div>

                                </template>

                                <template v-else>
                                    <div class="col-sm-12 text-center">
                                        <div id="load"><center><div id="preload"></div></center></div>
                                        <span style="font-weight:500">Creación de examen en curso...</span>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="single-review-st-item res-mg-t-30 table-mg-t-pro-n">
                        <div class="single-review-st-hd col-sm-12 center">
                            <h2>Previsualización del examen</h2>
                        </div>

                        <div class="col-sm-12" style="border:1px solid purple;margin-bottom:10px"></div>

                        <template v-for="(ex,index) in dataExams">
                            <div class="col-sm-12" :key="index" style="border:1px solid #DBDBDB;border-radius:5px;padding:0.75rem;margin-bottom:10px">
                                <div v-if="ex.file" :style="`background-image:url(${ex.file});background-repeat:no-repeat;background-position:center;background-size:cover;width:100%;height:50px;float:left;margin-bottom:10px;position:relative;border:1px solid #DBDBDB;border-radius:5px;`">
                                </div>
                                <div style="width:100%;float:left;border-bottom:1px solid #DBDBDB;padding-bottom:10px">
                                    <div style="width:40%;float:left;padding:5px 0">
                                        <b style="font-size:20px;text-transform:uppercase;padding:0;width:100%;float:left;">{{ex.title}}</b>

                                    </div>
                                    <div style="width:60%;float:left;">
                                        <span style="font-size:15px;padding:0;text-align:right;color:#0874fa;width:100%;float:left;"><i class="material-icons" style="font-size:15px;margin-right:2px;">date_range</i>Inicio: {{ex.fechainicio.replace('00:00:00','')}} - Fin: {{ex.fechafin.replace('00:00:00','')}}</span>
                                        <span style="font-size:15px;padding:0;text-align:right;color:#858585;width:100%;float:left;"><i class="material-icons" style="font-size:15px;margin-right:2px;">schedule</i>Duracion: {{ex.duracion}}</span>
                                        <span style="font-size:15px;padding:0;text-align:right;color:#858585;width:100%;float:left;">Intentos: {{ex.intentos}}</span>
                                    </div>
                                </div>

                                <div class="col-sm-12" style="padding:0;position:relative">
                                    <div class="col-md-12 ql-editor ql-blank" v-html="ex.descripcion" style="padding:0;margin-top:15px;margin-bottom:10px;min-height:auto !important;"></div>

                                    <div class="col-sm-12" style="padding:0">
                                        <div class="col-sm-12 alertSuccess" style="padding:10px;font-size:16px">
                                            El examen tiene {{ex.questions ? ex.questions.length : 0}} preguntas. Para añadir preguntas haga click <b style="cursor:pointer" @click="addQuestions(ex)">Aquí.</b>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <AddQuestions
                :st="st"
                :examenoB="examen_obj"
                @createQuestions="createQuestions"
                @finishQuestions="finishQuestions"
            />
        </template>
    </div>
</template>
<style>
    .text_align_left{
        text-align: left;
    }
    .dropzone{
        border:1px solid #2C7696;
        height:100px;
        cursor:pointer;
        padding:0;
        margin-bottom:10px;
        position:relative;
        width:100%;
        float:left;
    }
    .dropzone.active{
        border:1px dashed #2C7696;
    }
    #vs1__combobox{
        height: 40px !important;
        border-radius: 0 !important;
        border: 1px solid #E5E6E7 !important;
        color: #9FA09C !important;
    }
</style>
<script>
import { VueEditor } from "vue2-editor";
import Quill from "quill";
import AddQuestions from './CreateQuestions.vue';
import DatePicker from 'vue2-datepicker';
export default {
    props:['st','groups','typeIntentos','loadRegister','dataExams'],
    data(){
        return{
            obj:[],
            intentos:[],
            examen_obj:{},
            images_array:'',
            active:false,
            drag:false,
            question_order:[
                {
                    code:true,
                    label:'Si'
                },
                {
                    code:false,
                    label:'No'
                }
            ],
            createExam:{
                contenido:'',
                date_init:'',
                date_finish:'',
                file:'',
                name_exam:'',
                time:'',
                num_try:'',
                selected_group:'',
                selected_typeIntentos:'',
                questions_order_selected:''
            },
            editorSettings: {
                modules: {
                    imageDrop: false,
                    imageResize: {
                        modules: [ 'Resize', 'DisplaySize' ]
                    },
                }
            },
            customToolbar: [
               [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["video"]
            ],
            addQuestiosByExam:false
        }
    },
    components:{
        VueEditor,
        DatePicker,
        AddQuestions
    },
    watch:{
        createExam:{
            handler(){
                let dt = this.createExam;

                if(new Date(dt.date_init).getTime() > new Date(dt.date_finish).getTime()){
                    this.$swal(
                        {
                            icon: 'error',
                            title: 'Se presento un error',
                            text: `La fecha de inicio no puede ser mayor a la fecha final`,
                        }
                    );

                    dt.date_init = '';
                    dt.date_finish = '';
                }

                if(dt.date_init && dt.date_finish && dt.time && dt.name_exam && dt.num_try && (dt.selected_group && dt.selected_group.length > 0) && dt.selected_typeIntentos && dt.questions_order_selected){
                    this.active = true;
                }else{
                    this.active = false;
                }
            },deep:true
        },
        groups(){
            this.init();
        },
        typeIntentos(){
            this.init();
        },
        loadRegister(){
            if(!this.loadRegister){
                this.createExam = {
                    contenido:'',
                    date_init:'',
                    date_finish:'',
                    file:'',
                    name_exam:'',
                    time:'',
                    num_try:'',
                    selected_group:'',
                    selected_typeIntentos:'',
                    questions_order_selected:''
                }
            }
        },
        '$route':{
            handler(){
                this.validateGet();
            },deep:true
        }
    },
    methods:{
        finishQuestions(){
            this.$router.push(`${this.$route.path}`).catch(err => {});
        },
        createQuestions(data){
            this.$emit('createQuestions',data)
        },
        validateGet(){
            if(this.$route.fullPath.indexOf('?') !== -1){
                this.addQuestiosByExam = true;
                this.examen_obj = {};
                if(this.dataExams.length > 0){
                    let id = Object.keys(this.$route.query)[0];
                    for(var i in this.dataExams){
                        if(this.dataExams[i].idcuestionario == id){
                            this.examen_obj = JSON.parse(JSON.stringify(this.dataExams[i]));
                        }
                    }
                }
            }else{
                this.addQuestiosByExam = false
            }
        },
        addQuestions(data){
            this.$router.push(`${this.$route.path}?${data.idcuestionario}`).catch(err => {});
        },
        sendExam(){
            this.$emit('createExam',this.createExam)
        },
        exit_div(ev){
            this.drag = false;
        },
        dragOverHandler(ev){
            ev.preventDefault();
            this.drag = true;
        },
        async dropHandler(ev){
            ev.preventDefault();
            this.createExam['file'] = {};
            if(ev.dataTransfer.items) {
                for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        this.createExam['file'] = ev.dataTransfer.items[i].getAsFile();
                        this.images_array = URL.createObjectURL(ev.dataTransfer.items[i].getAsFile());
                    }
                }
            }else{
            }
            this.drag = false;
            this.removeDragData(ev)
        },
        removeDragData(ev){
            if(ev.dataTransfer.items){
                ev.dataTransfer.items.clear();
            }else{
                ev.dataTransfer.clearData();
            }
        },
        quitar_img(i){
            this.images_array.splice(i, 1);
        },
        async fileChange(e){
            var files = e.target.files;
            this.createExam['file'] = {};
            for(var i in files){
                if(!isNaN(i)){
                    this.createExam['file'] = files.item(i);
                    this.images_array = URL.createObjectURL(files.item(i));
                }
            }
            e.target.value = "";
        },
        activar_input(){
            this.$refs['file_input'].click();
        },
        init(){
            this.validateGet();
            this.obj = [];
            for(const i in this.groups){
                this.obj.push({
                    code:this.groups[i].grupo,
                    label:this.groups[i].grupo
                })
            }
            this.intentos = [];
            for(const i in this.typeIntentos){
                this.intentos.push({
                    code:this.typeIntentos[i].aetipint_id,
                    label:this.typeIntentos[i].aetipint_descripcion
                })
            }
        }
    },
    mounted() {
        this.init();
    },
}
</script>