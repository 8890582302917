<template>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
        <div class="all-form-element-inner col-sm-12" style="position:relative;padding: 18px;border-radius:5px;min-height: calc(100vh - 290px);">
            <template v-if="!loadRegister">
                <form  v-on:submit.prevent="saveUser">
                    <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                        <div   class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Docente</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <div class="form-select-list">
                                    <select v-if="st.personLog.sub.datos_usuario.rol === 1" class="form-control custom-select-value" name="account" v-model="horario_data.teacher">
                                        <option disabled selected>Selecciona una opción</option>
                                        <template  v-for="(doc,index) in docents">
                                            <option :key="index" :value="doc.id">{{doc.name}} {{doc.last_name}}</option>
                                        </template>
                                    </select>
                                    <select v-else class="form-control custom-select-value" name="account" v-model="horario_data.teacher">
                                        <option disabled selected>Selecciona una opción</option>
                                        
                                            <option :value="st.personLog.sub.datos_usuario.idacademico">{{st.personLog.sub.datos_usuario.name}}</option>
                                        
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Grupo</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <input type="text" v-model="horario_data.group" class="form-control" placeholder="Grupo"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Día</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <div class="form-select-list">
                                    <select class="form-control custom-select-value" name="account" v-model="horario_data.day" placeholder="Día">
                                        <option value="2">Lunes</option>
                                        <option value="3">Martes</option>
                                        <option value="4">Miercoles</option>
                                        <option value="5">Jueves</option>
                                        <option value="6">Viernes</option>
                                        <option value="7">Sábado</option>
                                        <option value="1">Domingo</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Asignatura</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <input type="text" v-model="horario_data.asignments" class="form-control" placeholder="Asignatura"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Hora</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <date-picker v-model="horario_data.time" valueType="format" style="width:100%;height:40px;" placeholder="Hora" type="time"></date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Enlace</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <input type="text" v-model="horario_data.link" class="form-control" placeholder="Enlace"/>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="col-sm-12" style="padding:0">
                    <button class="btn success loginbtn" style="float:right;width:200px;margin-left:5px;" @click="saveUser">{{JSON.stringify(dataHorario) != '{}' && dataHorario.data.id ? 'Editar' : 'Registrar'}}</button>
                    <button class="btn danger loginbtn" style="float:right;width:200px" @click="cancel_edit_register">Cancelar</button>
                </div>
            </template>
            <template v-else>
                <div class="col-sm-12 text-center" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)">
                    <div id="load"><center><div id="preload"></div></center></div>
                    <span style="font-weight:500">{{JSON.stringify(dataHorario) != '{}' && dataHorario.data.id ? 'Edición en curso...' : 'Registro en curso...'}}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        props:['st','dataHorario','loadRegister','docents'],
        data(){
            return{
                horario_data:{
                    institucion_id:'',
                    ano_lectivo:'',
                    teacher:'' ,
                    group:'',
                    day:'',
                    asignments:'',
                    time:'',
                    link:''
                }
            }
        },
        components:{
            DatePicker
        },
        watch:{
            loadRegister:{
                handler(){
                    if(JSON.stringify(this.dataHorario) == '{}' && !this.dataHorario.id && this.loadRegister){
                        this.horario_data = {
                            institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                            ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                            teacher:'',
                            group:'',
                            day:'',
                            asignments:'',
                            time:'',
                            link:''
                        }
                    }
                },deep:true
            }
        },
        methods:{
            cancel_edit_register(){
                this.dataHorario = {}
                this.horario_data = {
                    institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                    ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                    teacher:'',
                    group:'',
                    day:'',
                    asignments:'',
                    time:'',
                    link:''
                }
            },
            saveUser(){
                let dt = this.horario_data;

                if(this.st.personLog.sub.datos_usuario.rol === 1 && dt.teacher == '' || dt.group == '' || dt.day == '' || dt.asignments == '' || dt.time == '' || dt.link == ''){
                    this.$swal(
                        {
                            icon: 'error',
                            title: 'Campos vacios',
                            text: `Ops... Parece que hay campos vacios, verifica`,
                        }
                    );
                }else if(dt.group == '' || dt.day == '' || dt.asignments == '' || dt.time == '' || dt.link == ''){
                         this.$swal(
                        {
                            icon: 'error',
                            title: 'Campos vacios',
                            text: `Ops... Parece que hay campos vacios, verifica`,
                        }
                    );
                }
                else{
                    if(JSON.stringify(this.dataHorario) != '{}' && this.dataHorario.data.id){
                        this.$emit('buttonActions','edit_horario',{data:this.horario_data})
                    }else{
                        
                        this.$emit('buttonActions','save_horario',{data:this.horario_data})
                    }
                }
            },
            init(){
                if(JSON.stringify(this.dataHorario) != '{}' && this.dataHorario.data.id){
                    let dt = this.dataHorario;
                        
                    //console.log(dt)
                    this.horario_data = {
                        institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                        ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                        id:dt.data.id,
                        teacher:'',
                        group:dt.group,
                        day:dt.day,
                        asignments:dt.data.asignatura,
                        time:dt.time,
                        link:dt.data.enlace
                    }
                }else{
                    this.horario_data = {
                        institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                        ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                        teacher:'',
                        group:'',
                        day:'',
                        asignments:'',
                        time:'',
                        link:''
                    }
                }
            }
        },
        beforeMount(){
            this.init();
        }
    }
</script>
<style>

</style>