<template>
    <div class="col-sm-12" style="height:100%;border-radius:5px;padding:10px;">
        <div class="col-sm-12" style="margin-bottom:10px">
            <span style="font-weight:400;padding:0;margin-bottom:10px" class="col-sm-12">{{title}}</span>
            <div class="upload-btn-wrapper col-sm-12" style="padding:0;margin-bottom:10px">
                <button class="btn btn-primary" @click="$refs['excel_csv'].click()">Subir Archivo</button>
                {{file_upload.name}}
                <input type="file" name="myfile" ref="excel_csv" accept=".csv" @change="uploadFile($event)"/>
            </div>
            <span class="downd_planti col-sm-12">Descargar plantilla</span>
            <button :class="`btn ${file_upload ? 'success' : 'default'}`" @click="file_upload ? uploadInfo() : ''">Cargar</button>
        </div>

        <div class="col-sm-12">
            <div class="col-sm-12 text-center" style="height:30px;background:#EFEFEF;border:1px solid #DBDBDB;padding:5px">
                <span style="font-weight:400">Explicación de cada campo</span>
            </div>
            <div class="col-sm-12" style="border-left:1px solid #DBDBDB;border-right:1px solid #DBDBDB;padding:0">
                <template v-for="(dt,index) in data">
                    <div :key="index" class="col-sm-12" style="border-bottom:1px solid #DBDBDB;padding:0;height:30px;">
                        <div class="col-sm-3" style="border-right:1px solid #DBDBDB;height:100%;padding:5px">
                            {{dt.label}}
                        </div>
                        <div class="col-sm-9" style="height:100%;padding:5px">
                            {{dt.content}}
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style>
    .downd_planti{
        font-weight:400;
        padding:0 !important;
        margin-bottom:10px;
        cursor:pointer;
    }
    .downd_planti:hover{
        text-decoration: underline;
    }
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        cursor:pointer;
    }
    .upload-btn-wrapper input[type=file] {
        display: none;
    }
</style>

<script>
export default {
    props:['title','data'],
    data(){
        return{
            file_upload:''
        }
    },
    watch:{
    },
    methods:{
        uploadFile(e){
            this.file_upload = e.target.files[0]
            //console.log(e.target.files[0])
        },
        uploadInfo(){
            this.$emit('buttonActions','uploadCsv',{data:this.file_upload})
        }
    }
}
</script>