import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/education/education.vue')
  },
  {
    path: '/recover/:token',
    name: 'recover_token',
    component: () => import('@/components/Login/RecoverToken.vue')
  },
  {
    path: '/students',
    name: 'students',
    component: () => import('../views/students/students.vue')
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: () => import('../views/teachers/teachers.vue')
  },
  {
    path: '/asignments',
    name: 'asignments',
    component: () => import('../views/asignments/asignments.vue')
  },
  {
    path: '/horario_acudiente',
    name: 'horario_acudiente',
    component: () => import('../views/asignments/asignments.vue')
  },
  {
    path: '/evaluacion',
    name: 'evaluacion',
    component: () => import('../views/evaluacion/evaluacion.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
