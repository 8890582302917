<template>
    <div class="col-md-12" style="height:100vh;padding:0 !important;">
        <template v-if="actual">
            <div class="login_video col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="col-md-12" style="text-align:center;vertical-alignment:auto;padding:10px;">
                    <img style="border-radius:10px;" src="@/assets/images/logo/Slide.0.jpg" alt="Bienvenida">
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4" style="height: 100vh;padding: 10px;">
                <div class="login_content">
                    <template v-if="login_normal">
                        <div class="login_change_icon_video">
                            <i class="material-icons">input</i>
                        </div>
                        <div class="col-md-12" style="top:50%;transform: translate(0,-50%);">
                            <div class="text-center m-b-md custom-login" style="margin-bottom:10px">
                                <img src="@/assets/images/logo/logo.png" alt="">
                            </div>
                            <div class="col-md-12">
                                <form v-on:submit.prevent="loginUser">
                                    <div class="form-group">
                                        <label class="control-label" for="username">Email o usuario</label>
                                        <input type="text" placeholder="Email o usuario" class="form-control" v-model="username">
                                    </div>
                                    <div class="form-group">
                                        <label class="control-label" for="password">Contraseña</label>
                                        <input type="password" placeholder="******" class="form-control" v-model="password">
                                    </div>
                                    <button class="btn btn-primary btn-block loginbtn" style="margin-bottom:20px">Ingresar</button>
                                </form>
                                <button class="btn success btn-block loginbtn">Crear cuenta</button>
                            </div>
                            <div class="col-md-12" style="margin-top:20px">
                                <div class="col-md-12 text-center" style="margin-bottom:30px" @click="login_normal = false">
                                    <span class="login_span_term no_sele">Recuperar clave</span>
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-12 text-truncate">
                                        <span class="login_span_term no_sele">Politica de datos</span>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-12 text-truncate" >
                                        <span class="login_span_term no_sele">terminos de servicio</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-md-12" style="top:50%;transform: translate(0,-50%);">
                            <RecoverPass @volverLogin="login_normal = true"/>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <RecoverToken/>
        </template>
    </div>
</template>

<script>
    import Vuex from '@/store'
    import jwt_decode from "jwt-decode";
    import RecoverPass from './RecoverPass.vue'
    import RecoverToken from './RecoverToken.vue'
    export default {
        props:['st'],
        data(){
            return{
                login_normal:true,
                show_input:true,
                username:'',
                password:'',
                actual: true
            }
        },
        components:{
            RecoverPass,
            RecoverToken
        },
        methods:{
            async loginUser(){
                if(this.username && this.password){
                    let formData = new FormData();
                    //formData.append('method','POST');
                    formData.append('username',this.username);
                    formData.append('password',this.password);
                    let response = await Vuex.dispatch('goToBackend',{
                        route:'/users/login',
                        method:'POST',
                        identify:'logearse',
                        data: formData
                    })
                    if(response.status == 'success'){
                        this.$session.start();
                        this.$session.set('user',jwt_decode(response.data));
                        this.st.session = true
                        this.st.personLog = jwt_decode(response.data)
                        this.st.token = response.data
                        localStorage.setItem('token', response.data);
                        //console.log(jwt_decode(response.data))
                    }else{
                        this.$swal(
                            {
                                icon: 'error',
                                title: '¡Parece que hubo un error!',
                                text: `Nombre de usuario o contraseña invalidos`,
                            }
                        );
                    }

                }else{
                    this.$swal(
                        {
                            icon: 'error',
                            title: 'Campos vacios',
                            text: `Ops... Parece que hay campos vacios, verifica`,
                        }
                    );
                }
            }
        },
        beforeMount(){
            if(this.$route.name == 'recover_token'){
                this.actual = false;
            }else{
                this.actual = true;
            }
        }
    }
</script>