import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSession from 'vue-session'
import VueTippy, { TippyComponent } from "vue-tippy"
import VueResource from 'vue-resource'
import VueSweetalert2 from 'vue-sweetalert2';
import VueClipboard from 'vue-clipboard2'
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select'


Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(VueSession)
Vue.use(VueResource)
Vue.component('v-select', vSelect)
Vue.use(VueSweetalert2);
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      },
      hide: {
        enabled: false
      }
    }
  }
});
Vue.component("tippy", TippyComponent);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
