<template>
    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
            <div class="all-form-element-inner col-sm-12" style="position:relative;padding: 18px;border-radius:5px;min-height: calc(100vh - 290px);">
                <template v-if="!loadRegister">
                    <form  v-on:submit.prevent="saveUser">
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Codigo</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.code" class="form-control" placeholder="Codigo"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Grupo</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.group" class="form-control" placeholder="Grupo"/>
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-sm-12" style="border:1px solid #DBDBDB;margin-bottom:10px;"></div>-->
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Nombres</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.name" class="form-control" placeholder="Nombres"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Apellidos</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.last_name" class="form-control" placeholder="Apellidos"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Número de identificación</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="number" v-model="user_data.number_identification" class="form-control" placeholder="Número de identificación"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Fecha de nacimiento</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <date-picker v-model="user_data.date_birth" valueType="format" style="width:100%;height:40px;" placeholder="Fecha de nacimiento"></date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Genero</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <div class="form-select-list">
                                        <select class="form-control custom-select-value" name="account" v-model="user_data.gender" placeholder="Genero">
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                            <option value="I">Indeterminado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro">Dirección</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.direction" class="form-control" placeholder="Dirección" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro">Teléfono</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.phone" class="form-control" placeholder="Teléfono" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Acudiente No. de identificación</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="number" v-model="user_data.number_identification_acu" class="form-control" placeholder="Acudiente No. de identificación"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Acudiente Nombres</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.acu_nombres" class="form-control" placeholder="Acudiente Nombres"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Acudiente Apellidos</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.acu_last_names" class="form-control" placeholder="Acudiente Apellidos"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Acudiente Genero</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <div class="form-select-list">
                                        <select class="form-control custom-select-value" name="account" v-model="user_data.acu_gender" placeholder="Acudiente Genero">
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                            <option value="I">Indeterminado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro">Acudiente Dirección</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.acu_direction" class="form-control" placeholder="Acudiente Dirección"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro">Acudiente Teléfono</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.acu_phone" class="form-control" placeholder="Acudiente Teléfono"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Correo electrónico</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.email" class="form-control" placeholder="Correo electrónico"/>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="col-sm-12" style="padding:0">
                        <button class="btn success loginbtn" style="float:right;width:200px;margin-left:5px;" @click="saveUser">{{JSON.stringify(dataUser) != '{}' && dataUser.aeestudiantes_id ? 'Editar' : 'Registrar'}}</button>
                        <button class="btn danger loginbtn" style="float:right;width:200px" @click="cancel_edit_register">Cancelar</button>
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-12 text-center" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)">
                        <div id="load"><center><div id="preload"></div></center></div>
                        <span style="font-weight:500">{{JSON.stringify(dataUser) != '{}' && dataUser.aeestudiantes_id ? 'Edición en curso...' : 'Registro en curso...'}}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        props:['st','dataUser','loadRegister'],
        data(){
            return{
                user_data:{
                    institucion_id:'',
                    ano_lectivo:'',
                    code:'',
                    group:'',
                    email:'',
                    name:'',
                    last_name:'',
                    number_identification:'',
                    date_birth:'',
                    gender:'',
                    direction:'',
                    phone:'',
                    acu_nombres:'',
                    acu_last_names:'',
                    number_identification_acu:'',
                    acu_gender:'',
                    acu_direction:'',
                    acu_phone:'',
                }
            }
        },
        components:{
            DatePicker
        },
        watch:{
            loadRegister:{
                handler(){
                    if(JSON.stringify(this.dataUser) == '{}' && !this.dataUser.id && this.loadRegister){
                        this.user_data = {
                            institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                            ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                            id_usu:this.st.personLog.sub.datos_usuario.id_usuario,
                            code:'',
                            group:'',
                            email:'',
                            name:'',
                            last_name:'',
                            number_identification:'',
                            date_birth:'',
                            gender:'',
                            direction:'',
                            phone:'',
                            acu_nombres:'',
                            acu_last_names:'',
                            number_identification_acu:'',
                            acu_gender:'',
                            acu_direction:'',
                            acu_phone:''
                        }
                    }
                },deep:true
            }
        },
        methods:{
            cancel_edit_register(){
                this.dataUser = {}
                this.user_data = {
                    institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                    ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                    id_usu:this.st.personLog.sub.datos_usuario.id_usuario,
                    code:'',
                    group:'',
                    email:'',
                    name:'',
                    last_name:'',
                    number_identification:'',
                    date_birth:'',
                    gender:'',
                    direction:'',
                    phone:'',
                    acu_nombres:'',
                    acu_last_names:'',
                    number_identification_acu:'',
                    acu_gender:'',
                    acu_direction:'',
                    acu_phone:'',
                }
            },
            saveUser(){
                let dt = this.user_data
                if(dt.code == '' || dt.group == '' || dt.email == '' || dt.name == '' || dt.last_name == '' || dt.number_identification == '' || dt.date_birth == '' || dt.gender == '' || dt.acu_nombres == '' || dt.acu_last_names == '' || dt.number_identification_acu == '' || dt.acu_gender == ''){
                    this.$swal(
                        {
                            icon: 'error',
                            title: 'Campos vacios',
                            text: `Ops... Parece que hay campos vacios, verifica`,
                        }
                    );
                }else{
                    if(JSON.stringify(this.dataUser) != '{}' && this.dataUser.aeestudiantes_id){
                        this.$emit('buttonActions','edit_user',{data:this.user_data})
                    }else{
                        this.$emit('buttonActions','save_user',{data:this.user_data})
                    }
                }
            },
            init(){
                if(JSON.stringify(this.dataUser) != '{}' && this.dataUser.aeestudiantes_id){
                    let dt = this.dataUser;

                    //console.log(dt)
                    this.user_data = {
                        institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                        ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                        id_usu:this.st.personLog.sub.datos_usuario.id_usuario,
                        code:dt.aeestudiantes_codigo,
                        group:dt.aeestudiantes_grupo,
                        id:dt.aeestudiantes_id,
                        email:dt.aeestudiantes_mail,
                        name:dt.aeestudiantes_nombres,
                        last_name:dt.aeestudiantes_apellidos,
                        number_identification:dt.aeestudiantes_identificacion,
                        date_birth:dt.aeestudiantes_fechanacimiento,
                        gender:dt.aeestudiantes_genero.toUpperCase(),
                        direction:dt.aeestudiantes_direccion,
                        phone:dt.aeestudiantes_telefono,
                        acu_nombres:dt.aeestudiantes_nombresacudiente,
                        acu_last_names:dt.aeestudiantes_apellidosacudiente,
                        number_identification_acu:dt.aeestudiantes_idenacudiente,
                        acu_gender:dt.aeestudiantes_generoacudiente,
                        acu_direction:dt.aeestudiantes_direccionacudiente,
                        acu_phone:dt.aeestudiantes_telefonoacudiente
                    }
                }else{
                    this.user_data = {
                        institucion_id:this.st.personLog.sub.datos_usuario.id_institucion,
                        ano_lectivo:this.st.personLog.sub.datos_usuario.id_anolectivo,
                        id_usu:this.st.personLog.sub.datos_usuario.id_usuario,
                        code:'',
                        group:'',
                        email:'',
                        name:'',
                        last_name:'',
                        number_identification:'',
                        date_birth:'',
                        gender:'',
                        direction:'',
                        phone:'',
                        acu_nombres:'',
                        acu_last_names:'',
                        number_identification_acu:'',
                        acu_gender:'',
                        acu_direction:'',
                        acu_phone:'',
                    }
                }
            }
        },
        beforeMount(){
            this.init();
        }
    }
</script>