<template>
    <div>
        <template v-for="(carts,index) in data">
            <div :key="index" class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="margin-bottom:10px">
                <div class="col-sm-12 analytics-sparkle-line reso-mg-b-30">
                    <div class="col-sm-12 analytics-content text-truncate" style="padding:0">
                        <span class="col-md-12 text-truncate titles_color_global" style="padding:0 0 5px 0;font-weight:500;font-size:16px" :content="carts.title" v-tippy="{arrow:true}">
                            {{carts.title}}
                        </span>
                        <div v-if="carts.images && carts.images.length > 0" class="col-md-12" style="margin-bottom:10px;position:relative">
                            <div class="col-md-12" style="padding:0;margin-bottom:10px">
                                <i v-if="carts.images.length > 1 && position_img_p[index] > 0" @click="changeImg('prev',index)" class="material-icons no_sele" style="cursor:pointer;position:absolute;left:-20px;top:40%">chevron_left</i>
                                <img :src="carts.images[position_img_p[index]].img" class="d-block w-100" style="border-radius:5px;width:100%;height:100px">
                                <i v-if="carts.images.length > 1 && position_img_p[index] === position_img_p[position_img_p.length-1]" @click="changeImg('next',index)" class="material-icons no_sele" style="cursor:pointer;position:absolute;right:-20px;top:40%">chevron_right</i>
                            </div>
                            <div class="col-md-12" style="padding:0;text-align:justify">
                                <template v-if="!show_more[index]">
                                    <div @click="show_complete_text('view_more',index)" class="col-md-12" style="padding:0;">
                                        <span class="text_color_global">{{carts.images[position_img_p[index]].text.length > 169 ? carts.images[position_img_p[index]].text.substring(0,169)+'...' : carts.images[position_img_p[index]].text}}</span>
                                        <span class="no_sele" style="color:blue;cursor:pointer;">{{carts.images[position_img_p[index]].text.length > 169 ? 'Ver mas' : ''}}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div @click="show_complete_text('view_less',index)" class="col-md-12" style="padding:0;">
                                        <span class="text_color_global">{{carts.images[position_img_p[index]].text}}</span>
                                        <span class="no_sele" style="color:blue;cursor:pointer;">Ver menos</span>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!--<h2>
                            <span class="counter" style="font-size:20px">{{carts.value}}</span>
                            <span class="tuition-fees">Tuition Fees</span>
                        </h2>-->
                        <template v-if="carts.percentage || carts.percentage === 0">
                            <span class="text-success" style="font-size:14px;">{{carts.percentage}}%</span>
                            <div class="progress m-b-0">
                                <div :class="`progress-bar progress-bar-success ${parseInt(carts.percentage) <= 20 ? 'ctn-vs-4' : parseInt(carts.percentage) > 20 && parseInt(carts.percentage) < 99 ? 'ctn-vs-1' : 'ctn-vs-3'} `" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width:${carts.percentage}%;`"> <span class="sr-only">20% Complete</span> </div>
                            </div>
                        </template>
                        <div class="buttons_cards" v-if="JSON.stringify(carts.button) != '{}'" @click="$emit('buttonsActions',{case:carts.button.case,data:carts.questions})">
                            <button :class="`btn ${carts.button.type ? carts.button.type : 'default'} btn-block loginbtn`">{{carts.button.title}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props:['data'],
    data(){
        return{
            position_img_p:[],
            show_more:[]
        }
    },
    watch:{
    },
    methods:{
        changeImg(caso,ind){
            if(caso == 'next'){
                this.position_img_p[ind]++
            }else{
                this.position_img_p[ind]--
            }
            let arr = this.position_img_p.slice();
            this.position_img_p = []
            this.position_img_p = arr
        },
        show_complete_text(caso,ind){
            if(caso == 'view_more'){
                this.show_more[ind] = true
            }else{
                this.show_more[ind] = false
            }
            let arr = this.show_more.slice();
            this.show_more = []
            this.show_more = arr
        }
    },
    beforeMount(){
        for(const i in this.data){
            this.position_img_p[i] = {}
            if(this.data[i].images && this.data[i].images.length > 0){
                this.position_img_p[i] = 0
                this.show_more[i] = false
            }
        }
    }
}
</script>