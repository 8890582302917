<template>
    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
            <div class="all-form-element-inner col-sm-12" style="position:relative;padding: 18px;border-radius:5px;min-height: calc(100vh - 290px);">
                <template v-if="!loadRegister">
                    <form  v-on:submit.prevent="saveUser">
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Correo electrónico</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.email" class="form-control" placeholder="Correo electrónico"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Nombres</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.name" class="form-control" placeholder="Nombres"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Apellidos</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.last_name" class="form-control" placeholder="Apellidos"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Número de identificación</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="number" v-model="user_data.number_identification" class="form-control" placeholder="Número de identificación"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Fecha de nacimiento</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <date-picker v-model="user_data.date_birth" valueType="format" style="width:100%;height:40px;" placeholder="Fecha de nacimiento"></date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Genero</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <div class="form-select-list">
                                        <select class="form-control custom-select-value" name="account" v-model="user_data.gender">
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                            <option value="I">Indeterminado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro">Dirección</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.direction" class="form-control" placeholder="Dirección" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label class="login2 pull-right pull-right-pro">Teléfono</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                    <input type="text" v-model="user_data.phone" class="form-control" placeholder="Teléfono" />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="col-sm-12" style="padding:0">
                        <button class="btn success loginbtn" style="float:right;width:200px;margin-left:5px;" @click="saveUser">{{JSON.stringify(dataUser) != '{}' && dataUser.aedocentes_id ? 'Editar' : 'Registrar'}}</button>
                        <button class="btn danger loginbtn" style="float:right;width:200px" @click="cancel_edit_register">Cancelar</button>
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-12 text-center" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)">
                        <div id="load"><center><div id="preload"></div></center></div>
                        <span style="font-weight:500">{{JSON.stringify(dataUser) != '{}' && dataUser.aedocentes_id ? 'Edición en curso...' : 'Registro en curso...'}}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        props:['dataUser','loadRegister'],
        data(){
            return{
                user_data:{
                    email:'',
                    name:'',
                    last_name:'',
                    number_identification:'',
                    date_birth:'',
                    gender:'Seleccione una opción',
                    direction:'',
                    phone:''
                }
            }
        },
        components:{
            DatePicker
        },
        watch:{
            loadRegister:{
                handler(){
                    if(JSON.stringify(this.dataUser) == '{}' && !this.dataUser.id && this.loadRegister){
                        this.user_data = {
                            email:'',
                            name:'',
                            last_name:'',
                            number_identification:'',
                            date_birth:'',
                            gender:'Seleccione una opción',
                            direction:'',
                            phone:''
                        }
                    }
                },deep:true
            }
        },
        methods:{
            cancel_edit_register(){
                this.dataUser = {}
                this.user_data = {
                    email:'',
                    name:'',
                    last_name:'',
                    number_identification:'',
                    date_birth:'',
                    gender:'Seleccione una opción',
                    direction:'',
                    phone:''
                }
            },
            saveUser(){
                let dt = this.user_data
                if(dt.email == '' || dt.name == '' || dt.last_name == '' || dt.number_identification == '' || dt.date_birth == '' || dt.gender == ''){
                    this.$swal(
                        {
                            icon: 'error',
                            title: 'Campos vacios',
                            text: `Ops... Parece que hay campos vacios, verifica`,
                        }
                    );
                }else{
                    if(JSON.stringify(this.dataUser) != '{}' && this.dataUser.aedocentes_id){
                        this.$emit('buttonActions','edit_user',{data:this.user_data})
                    }else{
                        this.$emit('buttonActions','save_user',{data:this.user_data})
                    }
                }
            },
            init(){
                if(JSON.stringify(this.dataUser) != '{}' && this.dataUser.aedocentes_id){
                    console.log(this.dataUser)
                    this.user_data = {
                        id:this.dataUser.aedocentes_id,
                        email:this.dataUser.aedocentes_mail,
                        name:this.dataUser.aedocentes_nombres,
                        last_name:this.dataUser.aedocentes_apellidos,
                        number_identification:this.dataUser.aeusu_llave,
                        date_birth:this.dataUser.aedocentes_fechanacimiento,
                        gender:this.dataUser.aedocentes_genero.toUpperCase(),
                        direction:this.dataUser.aedocentes_direccion,
                        phone:this.dataUser.aedocentes_telefono
                    }
                }else{
                    this.user_data = {
                        email:'',
                        name:'',
                        last_name:'',
                        number_identification:'',
                        date_birth:'',
                        gender:'Seleccione una opción',
                        direction:'',
                        phone:''
                    }
                }
            }
        },
        beforeMount(){
            this.init();
        }
    }
</script>