<template>
    <div class="col-sm-12" style="">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: calc(100vh - 218px);max-height:auto;background: white;">
            <div class="col-sm-12 sparkline12-list">
                <div v-if="instituto_rol" class="sparkline12-hd col-sm-12" style="padding:0">
                    <Windows
                        :data="windows"
                        @clickWindow="clickWindow"
                    />
                    <CreateStudents
                        v-if="windows[0].active"
                        :st="st"
                        @buttonActions="buttonActions"
                        :dataUser="dataUser"
                        :loadRegister="loadRegister"
                    />
                    <Tables
                        v-if="windows[1].active"
                        :st="st"
                        :dataT="data_table"
                        @edit="editUser"
                        @delete="deleteUsr"
                        @sendEmail="sendEmail"
                    />
                    <GestionMasiva
                        v-if="windows[2].active"
                        title="Cargar todos los studiantes"
                        :data="gestionMas"
                        @buttonActions="buttonActions"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CreateStudents from '@/views/students/components/createUsers.vue'
import Windows from '@/components/Scheduler/Windows.vue'
import Tables from '@/components/Scheduler/Tables.vue'
import GestionMasiva from '@/components/Scheduler/GestionMasiva.vue'
const Cryptr = require('cryptr');

export default{
    props:['st','outPutBackend'],
    data(){
        return{
            show:false,
            loadRegister:false,
            windows:[
                {
                    id:1,
                    label:'Gestión',
                    active:true
                },
                {
                    id:2,
                    label:'Listados',
                    active:false
                },
                {
                    id:3,
                    label:'Gestión masiva',
                    active:false
                }
            ],
            data_teachers:[],
            data_table:{
                head:['#','Docente','Identificación','Teléfonos','Correo electronico','Acciones'],
                rows:[]
            },
            send_horario:[],
            teacher_rol:false,
            instituto_rol:false,
            dataUser:{},
            gestionMas:[
                {
                    label: 'NO. IDENTIFICACIÓN',
                    content: 'Cédula de ciudadania del docente'
                },
                {
                    label: 'NOMBRES',
                    content: 'Nombres del docente, sin apellidos'
                },
                {
                    label: 'APELLIDOS',
                    content: 'Apellidos del docente'
                },
                {
                    label: 'FECHA DE NACIMIENTO',
                    content: 'La fecha de nacimiento del docente en el formato: AAAA-MM-DD (1977-07-13)'
                },
                {
                    label: 'GENERO',
                    content: 'F Si es maestra, M Si es profesor, I Si es indefinido'
                },
                {
                    label: 'TITULO O PROFESIÓN',
                    content: 'Titulo del docente'
                },
                {
                    label: 'DIRECCIÓN',
                    content: 'Dirección de domicilio del docente'
                },
                {
                    label: 'TELÉFONO',
                    content: 'Teléfono de contacto principal del docente'
                },
                {
                    label: 'MAIL',
                    content: 'Correo electrónico del docente'
                },
            ]
        }
    },
    components:{
        CreateStudents,
        Windows,
        Tables,
        GestionMasiva,
    },
    watch:{
        outPutBackend:{
            handler(){
                let response = this.outPutBackend
                switch(response.identify){
                    case 'new_user':
                        //console.log(response);
                        this.returnStudents();

                        this.loadRegister = false

                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Registro exitoso',
                                text: `Se registro correctamente la persona "${response.data.sub.first_name} ${response.data.sub.last_name}"`,
                            }
                        );
                    break;
                    case 'edit_user':
                        //console.log('edit_user',response)
                        this.returnStudents();

                        this.loadRegister = false

                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Edición exitosa',
                                text: `Se edito correctamente la persona "${response.data.sub.first_name} ${response.data.sub.last_name}"`,
                            }
                        );
                    break;
                    case 'delete_user':
                        this.returnStudents();
                        //console.log('elimine',response)
                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Eliminación exitosa',
                                text: `Se elimino correctamente la persona "${response.data.sub.aeestudiantes_nombres} ${response.data.sub.aeestudiantes_apellidos}"`,
                            }
                        );
                    break;
                    case 'allstudents':
                        //console.log(response);
                        this.data_teachers = []

                        this.data_table = {
                            head:['#','Estudiante','Grupo','Acuediente','Telefonos','Acciones'],
                            width:[50,230],
                            rows:[]
                        }
                        for(const i in response.data.sub){
                            this.data_table.rows.push(
                                {
                                    0:parseInt(i)+1,
                                    1:response.data.sub[i].aeestudiantes_nombres+' '+response.data.sub[i].aeestudiantes_apellidos,
                                    2:response.data.sub[i].aeestudiantes_grupo,
                                    3:response.data.sub[i].aeestudiantes_nombresacudiente+' '+response.data.sub[i].aeestudiantes_apellidosacudiente,
                                    4:response.data.sub[i].aeestudiantes_telefono,
                                    5:[true,true,true],
                                    allData:response.data.sub[i]
                                }
                            )
                        }
                    break;
                    case 'select_horario':
                      this.send_horario = []
                      this.show = true
                        for(const i in response.data.sub){
                             this.send_horario.push(
                                {
                                id:response.data.sub[i].horariode,
                                name:response.data.sub[i].aeasignaciones_hora,
                                last_name:'',
                                identification:response.data.sub[i].docente,
                                phone:response.data.sub[i].aeasignaciones_asignatura,
                                email:response.data.sub[i].aeasignaciones_enlace
                                }
                            )
                        }
                    break
                    case 'sendEmail':
                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Envío exitoso',
                                text: `Se envio correctamente el correo electronico`,
                            }
                        );
                    break;
                }
            }
        }
    },
    methods:{
        sendEmail(data){

            console.log(data)

            let formData = new FormData();

            formData.append('id_estudiante',data.aeestudiantes_id)
            formData.append('id_usuario',data.aeusu_id)

            this.$emit('goBackend',{
                route:'/users/rememberpass',
                method:'POST',
                identify:'sendEmail',
                data: formData
            })
        },
        deleteUsr(data){

            this.$swal({
                title: '¿Estás seguro/a de eliminar este usuario?',
                text: "Una vez eliminado no podra recuperar el usuario",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    let formDataDelete = new FormData();
                    formDataDelete.append('id',parseInt(data.aeestudiantes_id));
                    formDataDelete.append('id_usu',parseInt(this.st.personLog.sub.datos_user.aeusu_id));

                    this.$emit('goBackend',{
                        route:'/students/delstudents',
                        method:'PUT',
                        identify:'delete_user',
                        data: formDataDelete
                    })
                }
            })
        },
        editUser(data){
            console.log(data)
            this.windows[0].active = true
            this.windows[1].active = false
            this.windows[2].active = false
            this.dataUser = data
        },
        clickWindow(data){
            for(const i in this.windows){
                if(`${data.data.id}` === `${this.windows[i].id}`){
                    this.windows[i].active = true
                }else{
                    this.windows[i].active = false
                }
            }
        },
        buttonActions(caso,dataComponent){
            switch(caso){
                case 'save_user':
                    //console.log(dataComponent.data)
                    let formDataNewUser = new FormData();
                    formDataNewUser.append('institucion_id',dataComponent.data.institucion_id)
                    formDataNewUser.append('ano_lectivo',dataComponent.data.ano_lectivo)
                    formDataNewUser.append('first_name',dataComponent.data.name)
                    formDataNewUser.append('last_name',dataComponent.data.last_name)
                    formDataNewUser.append('date_birth',dataComponent.data.date_birth)
                    formDataNewUser.append('direction',dataComponent.data.direction)
                    formDataNewUser.append('email',dataComponent.data.email)
                    formDataNewUser.append('gender',dataComponent.data.gender)
                    formDataNewUser.append('number_identification',dataComponent.data.number_identification)
                    formDataNewUser.append('phone',dataComponent.data.phone)
                    formDataNewUser.append('code',dataComponent.data.code)
                    formDataNewUser.append('acu_direction',dataComponent.data.acu_direction)
                    formDataNewUser.append('acu_gender',dataComponent.data.acu_gender)
                    formDataNewUser.append('acu_last_names',dataComponent.data.acu_last_names)
                    formDataNewUser.append('acu_nombres',dataComponent.data.acu_nombres)
                    formDataNewUser.append('acu_phone',dataComponent.data.acu_phone)
                    formDataNewUser.append('group',dataComponent.data.group)
                    formDataNewUser.append('number_identification_acu',dataComponent.data.number_identification_acu)

                    this.$emit('goBackend',{
                        route:'/students/registro',
                        method:'POST',
                        identify:'new_user',
                        data: formDataNewUser
                    })

                    this.loadRegister = true
                break;
                case 'edit_user':

                    let formDataEditUser = new FormData();
                    formDataEditUser.append('institucion_id',dataComponent.data.institucion_id)
                    formDataEditUser.append('id_usu',dataComponent.data.id_usu)
                    formDataEditUser.append('ano_lectivo',dataComponent.data.ano_lectivo)
                    formDataEditUser.append('id',dataComponent.data.id)
                    formDataEditUser.append('first_name',dataComponent.data.name)
                    formDataEditUser.append('last_name',dataComponent.data.last_name)
                    formDataEditUser.append('date_birth',dataComponent.data.date_birth)
                    formDataEditUser.append('direction',dataComponent.data.direction)
                    formDataEditUser.append('email',dataComponent.data.email)
                    formDataEditUser.append('gender',dataComponent.data.gender)
                    formDataEditUser.append('number_identification',dataComponent.data.number_identification)
                    formDataEditUser.append('phone',dataComponent.data.phone)
                    formDataEditUser.append('code',dataComponent.data.code)
                    formDataEditUser.append('acu_direction',dataComponent.data.acu_direction)
                    formDataEditUser.append('acu_gender',dataComponent.data.acu_gender)
                    formDataEditUser.append('acu_last_names',dataComponent.data.acu_last_names)
                    formDataEditUser.append('acu_nombres',dataComponent.data.acu_nombres)
                    formDataEditUser.append('acu_phone',dataComponent.data.acu_phone)
                    formDataEditUser.append('group',dataComponent.data.group)
                    formDataEditUser.append('number_identification_acu',dataComponent.data.number_identification_acu)

                    this.$emit('goBackend',{
                        route:'/students/UpdateStudents',
                        method:'PUT',
                        identify:'edit_user',
                        data: formDataEditUser
                    })

                    this.loadRegister = true
                break;
                case 'uploadCsv':
                    console.log(dataComponent.data)
                break;
            }
        },
        returnStudents(){
            if(`${this.st.personLog.sub.datos_usuario.rol}` == '1' || `${this.st.personLog.sub.datos_usuario.rol}` == '2'){
                //console.log('holaa');
                let formData = new FormData();
                formData.append('id_institucion',`${this.st.personLog.sub.datos_usuario.id_institucion}`)
                formData.append('id_usu',`${this.st.personLog.sub.datos_usuario.id_usuario}`)
                formData.append('ano_lectivo',`${this.st.personLog.sub.datos_usuario.id_anolectivo}`)
                formData.append('rol',`${this.st.personLog.sub.datos_usuario.rol}`)
                this.instituto_rol = true
                this.$emit('goBackend',{
                    route:'/students/allstudents',
                    method:'POST',
                    identify:'allstudents',
                    data: formData
                })
            }else{
                this.teacher_rol = true
                let formData = new FormData();
                formData.append('group',`${this.st.personLog.sub.user.group}`)
                this.$emit('goBackend',{
                    route:'/asignments/horario',
                    method:'POST',
                    identify:'select_horario',
                    data: formData
                })
            }
        }
    },
    beforeMount(){
        this.returnStudents();
    }
}
</script>
