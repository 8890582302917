<template>
    <div class="col-sm-12" style="height:100%;border-radius:5px;padding:10px;position:relative">
        <template v-if="selectGroup">
            <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                <div class="col-sm-12" style="margin-bottom:10px;">
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="padding:0">
                            <label class="login2 pull-right-pro"><span style="color:red">*</span>Grupo</label>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-12" style="padding:0">
                            <div class="form-select-list">
                                <select class="form-control custom-select-value" name="account" v-model="seletec" @change="consultar">
                                    <option value="" disabled selected>Seleccione una opción</option>
                                    <template v-for="(dt,index) in obj">
                                        <option :key="index" :value="dt.value">{{dt.label}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="selectStudent">
            <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                <div class="col-sm-12" style="margin-bottom:10px;">
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" style="padding:0">
                            <label class="login2 pull-right-pro"><span style="color:red">*</span>Estudiante:</label>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-12" style="padding:0">
                            <div class="form-select-list">
                                <select class="form-control custom-select-value" name="account" v-model="seletec" @change="consultar">
                                    <template v-for="(dt,index) in obj">
                                        <option :key="index" :value="dt.value">{{dt.label}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="col-sm-6" style="margin-bottom:10px;padding:0">
            <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-1 col-xl-1" style="padding:12px 0">
                        <label class="login2">Filas</label>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-9 col-xs-12" style="margin-bottom:10px;padding:0">
                        <div class="form-select-list">
                            <select class="form-control custom-select-value" name="account" v-model="selected_row">
                                <option v-for="(r,index) in rows" :key="index" :value="r">{{r}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="margin-bottom:10px;padding:0">
            <div class="col-md-12" style="padding:0;position:relative">
                <i class="material-icons" style="position:absolute;color:#DBDBDB;top:8px;left:5px">search</i>
                <input type="text" class="form-control" placeholder="Buscar" style="padding:0 30px" />
            </div>
        </div>
        <div ref="structura_scroll" class="col-sm-12" @mouseup="cancelMoveScroll($event)" @mouseleave="cancelMoveScroll($event)" @mousedown="moveScroll_down($event)" style="display:flex;overflow:auto hidden !important;padding:0;width:100%;user-select: none;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;">
            <div ref="width_scroll" style="display:flex;min-height: auto !important;">
                <template v-for="(hd,index_hd) in data_selected.head">
                    <vue-resizable
                        :key="index_hd"
                        :min-width="data_selected.width[index_hd] && index_hd !== 0  ? data_selected.width[index_hd] : 100"
                        :width="data_selected.width[index_hd] && index_hd !== 0  ? data_selected.width[index_hd] : 100"
                        @resize:start="resize_activation_propague = true"
                        @resize:end="resize_activation_propague = false"
                        :active="['r']"
                        :style="`min-width:${data_selected.width && data_selected.width[index_hd] && index_hd !== 0 ? data_selected.width[index_hd] : '100'}px !important;height:auto !important;padding:0;${index_hd == (data_selected['head'].length - 1) ? 'border-right:1px solid #DBDBDB' : ''};border-bottom:1px solid #DBDBDB;border-left:1px solid #DBDBDB;border-top:1px solid #DBDBDB;position:relative`"
                    >
                        <div  class="col-sm-12" :style="`top:${top_head}px;position:absolute;left:0 !important;padding:0;z-index:10;width:100%;`">
                            <div class="col-sm-12 text-truncate" :style="`background:rgb(44, 118, 149);padding:0;border-bottom:1px solid #D6D6D6;height:40px;position:sticky !important;top:0px;text-align:center;padding:10px 20px 5px 10px !important;`">
                                <span style="font-weight:500;color:white;">{{hd}}</span>
                            </div>
                        </div>
                        <div class="col-sm-12 container_rows" style="padding:0;margin-top:40px">
                            <template v-for="(body,inx_bo) in data_selected.rows">
                                <div :key="inx_bo" @mouseover="hover_row(inx_bo)" @mouseleave="hover_row(null,$event)" :class="`col-sm-12 text-truncate${hover_r == inx_bo ? ' row_active' : ''}`" :style="`padding:0;${inx_bo != (data_selected.rows.length - 1) ? 'border-bottom:1px solid #D6D6D6' : ''};height:80px;top:0px;text-align:left;padding:10px 14px 5px 10px !important;'}`">
                                    <template v-if="body[index_hd] && Object.keys(body[index_hd]).length !== 0 && typeof body[index_hd] == 'object'">
                                        <div class="col s12 text-truncate" style="position:relative;padding-left:3px">
                                            <i class="material-icons" style="font-size:18px;position:absolute;top:0px;left:0;color:#8a289c">menu_book</i>
                                            <span style="padding-left:17px;color: #333;font-weight: 500;text-transform:uppercase">{{body[index_hd].asignatura}}</span>
                                        </div>
                                        <div class="col s12 text-truncate" style="position:relative;padding-left:3px">
                                            <i class="material-icons" style="font-size:18px;position:absolute;top:0px;left:0px;color:#bb4848">person</i>
                                            <span style="padding-left:17px;font-size:14px;font-weight:400">{{body[index_hd].docente.toUpperCase()}}</span>
                                        </div>
                                        <div class="col s12 text-truncate" style="position:relative;padding-left:3px">
                                            <i class="material-icons" style="font-size:18px;position:absolute;top:2px;left:0px;color:#307cda">link</i>
                                            <span @click="openLink(body[index_hd].enlace)" class="style_span_link">{{body[index_hd].enlace}}</span>
                                        </div>
                                        <div v-if="`${st.personLog.sub.datos_usuario.rol}` === '1' || `${st.personLog.sub.datos_usuario.rol}` === '2'" style="position:absolute;top:0;right:5px">
                                            <button class="" @click="showMenu(index_hd,body[index_hd].id,$event)" style="text-align:center;position:relative;background:white;color:black;border:1px solid black;width:20px;height:20px;border-radius:5px">
                                                <i class="material-icons" style="font-size:15px;position:absolute;top:1.5px;left:2px">more_vert</i>
                                            </button>
                                            <div v-if="`${index_hd}_${body[index_hd].id}` === index_h" class="z-depth-2" :style="`border-radius:5px;background:white;position:fixed;width:100px;border:1px solid #DBDBDB;z-index:100;left:${left}px;top:${top}px`">
                                                <div class="col s12 span_click_dt no_sele" @click="actionButtons('edit',index_hd,body[index_hd],inx_bo)">
                                                    <i class="material-icons">edit</i>
                                                    <span style="padding-left:25px">Editar</span>
                                                </div>
                                                <div v-clipboard:success="onCopy" v-clipboard:copy="`${body[index_hd].asignatura.toUpperCase()} - ${body[index_hd].docente.toUpperCase()} - ${body[index_hd].enlace}`" class="col s12 span_click_dt no_sele">
                                                    <i class="material-icons">content_copy</i>
                                                    <span style="padding-left:25px">Copiar</span>
                                                </div>
                                                <div class="col s12 span_click_dt no_sele" @click="actionButtons('delete',index_hd,body[index_hd],inx_bo)">
                                                    <i class="material-icons">delete</i>
                                                    <span style="padding-left:25px">Eliminar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="typeof body[index_hd] == 'string'">
                                        <div class="col s12" style="text-align:center">
                                            <span>{{body[index_hd].split('_')[0]}}</span>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </vue-resizable>
                </template>
            </div>
        </div>
        <div class="col-md-12" style="position:relative;min-height:30px">
            <div class="text-center" style="position:absolute;transform:translate(-50%,-50%);top:70%;left:50%;"><!--width: 100%;overflow-x: auto;overflow-y: hidden;-->
                <template v-for="(c,inx_cant) in pages_arr">
                    <span v-if="inx_cant < 10" :key="inx_cant" :class="`pagination_span ${`${page_selected.page}` == `${c.page}` ? 'active' : ''} no_sele`" @click="change_page(c)">
                        <span style="padding:2px">{{c.page}}</span>
                    </span>
                </template>
                <template v-if="pages_arr.length > 10">
                    <span style="font-weight:500">.....</span>
                    <span v-if="pages_arr.length > 10" :class="`pagination_span no_sele`"  @click="change_page(pages_arr[pages_arr.length-1])">
                        <span style="padding:2px">{{pages_arr[pages_arr.length-1].page}}</span>
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import VdtnetTable from 'vue-datatables-net'
import VueResizable from 'vue-resizable'
export default {
    props:['st','head','dataT','selectGroup','selectStudent','delete_h','groups'],
    data(){
        return{
            top_head:0,
            calculos: 0,
            resize_activation_propague:false,
            move:false,
            copy_position_init: '',
            position_init:'',
            settime_width:null,
            top:'',
            left:'',
            hover_r: null,
            rows:[5,10,20,30,50],
            data_selected:{},
            selected_row:30,
            pages:0,
            pages_arr:[],
            page_selected:{
                page:1
            },
            show_menu:false,
            index_h:'',
            seletec:1,
            obj:[
                {
                    value:1,
                    label:'Grupo 1'
                },
                {
                    value:2,
                    label:'Grupo 2'
                },
                {
                    value:3,
                    label:'Grupo 3'
                },
                {
                    value:4,
                    label:'Grupo 4'
                },
                {
                    value:5,
                    label:'Grupo 5'
                },
                {
                    value:6,
                    label:'Grupo 6'
                },
                {
                    value:7,
                    label:'Grupo 7'
                },
                {
                    value:8,
                    label:'Grupo 8'
                },
                {
                    value:9,
                    label:'Grupo 9'
                },
                {
                    value:10,
                    label:'Grupo 10'
                },
                {
                    value:11,
                    label:'Grupo 11'
                }
            ],
            status_var:false
        }
    },
    components:{
        VdtnetTable,
        VueResizable
    },
    watch:{
        'st.scroll_to':{
            handler(){
                //console.log(this.$refs['structura_scroll'])
                if(this.$refs['structura_scroll'] != undefined){
                    this.calculos = this.$refs['structura_scroll'].getBoundingClientRect().top;
                    if(this.calculos < 0){
                        this.top_head = (this.calculos * -1) + 60;
                    }else{
                        this.top_head = 0;
                    }
                }
            }
        },
        'st.windowSizes.width':{
            handler(){
                var ancho = window.innerWidth - 440;
                var width_for_one = ancho / 7;
                this.data_selected['width'] = Array(8).fill(width_for_one <= 100 ? 100 : width_for_one);
                let copy = JSON.stringify(this.data_selected)
                this.data_selected = {};
                this.data_selected = JSON.parse(copy);
            },deep: true
        },
        selected_row:{
            handler(){
                this.data_selected = {}
                this.change_rows();
            }
        },
        dataT:{
            handler(){
                if(!this.status_var){
                    this.data_selected = this.dataT
                    this.status_var = true;
                }
            },deep:true
        },
        delete_h:{
            handler(){
                if(this.delete_h){
                    this.$emit('changeDeleteh')
                    this.data_selected = this.dataT
                }
            }
        }
    },
    methods:{
        onCopy(){
            this.index_h = '';
        },
        moveScroll_down(e){
            setTimeout(()=>{
                if(!this.resize_activation_propague && e.button == 0){
                    e.preventDefault()
                    this.move = true;
                    this.position_init = this.$refs['structura_scroll'].scrollLeft;
                    this.copy_position_init = e.clientX;
                    document.onmousemove = this.moverScroll;
                }
            },1)
        },
        moverScroll(e){
            if(this.move && !this.resize_activation_propague){
                this.$refs['structura_scroll'].scrollLeft = this.position_init + this.copy_position_init - e.clientX;
            }
        },
        cancelMoveScroll(e){
            e.preventDefault();
            this.move = false;
        },
        hover_row(id,event){
            if(id === null){
                if(event.relatedTarget.className != 'resizable-r'){
                    this.hover_r = null;
                }
            }else{
                this.hover_r = id;
            }
        },
        consultar(){
            this.$emit('sendGroup',{group:this.seletec})
            this.status_var = false;
        },
        cambios_width(){
            var ancho = window.innerWidth - 440;
            var width_for_one = ancho / 7;
            this.data_selected['width'] = Array(8).fill(width_for_one <= 100 ? 100 : width_for_one);
        },
        showMenu(index,id,e){
            //console.log(e)

            if((window.innerHeight - 80) > e.clientY && (window.innerWidth - 200) > e.clientX){
                this.left = e.clientX;
                this.top = e.clientY;
            }else if((window.innerHeight - 80) < e.clientY){
                this.left = e.clientX;
                this.top = window.innerHeight - 80;
            }else if((window.innerWidth - 200) < e.clientX){
                this.left = window.innerWidth - 210;
                this.top = e.clientY;
            }
            if(this.index_h === `${index}_${id}`){
                this.index_h = ''
            }else{
                this.index_h = `${index}_${id}`
            }
        },
        actionButtons(caso,day,data,index_rows){
            let time = ''
            for(const i in this.dataT.head){
                if(typeof this.dataT.rows[index_rows][i] == 'string'){
                    time = this.dataT.rows[index_rows][i]
                }
            }
            switch(caso){
                case 'edit':
                    this.$emit('buttonActions','edit_horario',{day:day,data:data,time:time.split('_')[0],group:this.seletec})
                break;
                case 'delete':
                    this.$emit('buttonActions','delete_horario',{day:day,data:data,time:time.split('_')[0],group:this.seletec})
                break;
            }
        },
        openLink(link){
            window.open(link, "Diseño Web")
        },
        change_page(data){
            this.status_var = true;
            this.page_selected = data;
            this.data_selected['head'] = this.dataT.head
            this.data_selected['link'] = this.dataT.link
            this.data_selected['rows'] = []
            this.cambios_width();
            for(let i = data.init; i < data.finish; i++){
                if(this.dataT.rows[i]){
                    this.data_selected.rows.push(this.dataT.rows[i])
                }
            }
        },
        change_rows(){
            let dt = [];
            this.pages_arr = []
            dt = this.dataT['rows']
            this.data_selected['head'] = this.dataT.head
            this.data_selected['link'] = this.dataT.link
            this.data_selected['rows'] = []
            this.cambios_width();
            for(let i = 0; i < this.selected_row; i++){
                if(this.dataT.rows[i]){
                    this.data_selected['rows'].push(this.dataT.rows[i])
                }
            }

            let arr_c = JSON.parse(JSON.stringify(this.data_selected))
            this.data_selected = {}
            this.data_selected = arr_c
            this.pages = parseInt(dt.length)/parseInt(this.selected_row)
            for(let p = 0; p < this.pages; p++){
                this.pages_arr.push({
                    page:p+1,
                    init:parseInt(this.selected_row)*parseInt(p),
                    finish:parseInt(this.selected_row)*(parseInt(p)+1)
                })
            }
            this.page_selected['page'] = 1
        },
        init(){
            this.data_selected = {}
            let dt = [];
            this.pages_arr = []
            dt = this.dataT['rows']
            this.data_selected['head'] = this.dataT.head
            this.data_selected['link'] = this.dataT.link
            this.data_selected['rows'] = []
            this.cambios_width();
            for(let i = 0; i < this.selected_row; i++){
                if(this.dataT.rows[i]){
                    this.data_selected['rows'].push(this.dataT.rows[i])
                }
            }

            this.pages = parseInt(dt.length)/parseInt(this.selected_row)

            for(let p = 0; p < this.pages; p++){
                this.pages_arr.push({
                    page:p+1,
                    init:parseInt(this.selected_row)*parseInt(p),
                    finish:parseInt(this.selected_row)*(parseInt(p)+1)
                })
            }
            this.page_selected['page'] = 1

            this.obj = []

            for(const i in this.groups){
                this.obj.push({
                    value:this.groups[i].grupo,
                    label:this.groups[i].grupo
                })
            }
        },
    },
    beforeMount(){
        document.getElementsByClassName('content_router_view')[0].addEventListener('scroll', (e)=>{
            this.index_h = '';
        })
        this.init();
    }
}
</script>