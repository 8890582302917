import Vue from 'vue'
import Vuex from 'vuex'
import Route from '@/router'
const {HOST,SOCKET_HOST,HOST_LOCAL} = require('./constants')

Vue.use(Vuex)
Vue.use(Route)

export default new Vuex.Store({
  state: {
    scroll_to:{},
    session:false,
    personLog:{},
    token:'',
    windowSizes:{
      width:0,
      height:0
    },
  },
  mutations: {
  },
  actions: {
    goToModule({},data){
      Route.push(data['route']).catch(err => {});
    },
    async goToBackend({state},data){
      switch (data.method) {
        case 'POST':
          let promise_post = new Promise(async(resolve,reject) => {
            //data.data.append('')
            //console.log(data.data.get('username'),data.data.get('password'))
            //console.log(data)
            this._vm.$http.post(`${HOST}${data.route}`,data.data,{
              headers:{
                'Content-type':'multipart/form-data',
                'Authorization' : `Bearer ${state.token}`
              },
              emulateJSON:true
            }).then((response)=>{
              if(response.status == '200' && response.statusText === 'OK'){
                resolve({
                  status:'success',
                  identify: data['identify'],
                  data: response.data.token
                })

                //console.log(response.data.token)
              }else{
                console.error(response.body.message)
                reject(response.body.message)
                if(data['identify'] == 'logearse'){
                    this._vm.$swal(
                        {
                            icon: 'error',
                            title: '¡Parece que hubo un error!',
                            text: response.body.message,
                        }
                    );
                }
              }
            },(error)=>{
              reject(error)
            })
          })
          return await promise_post;
          break;
        case 'GET':
          let promise_get = new Promise(async(resolve,reject) => {
            this._vm.$http.get(`${HOST}${data.route}`,{
              headers:{
                'Content-type':'multipart/form-data',
                'Authorization' : `Bearer ${state.token}`
              },
              emulateJSON:true
            }).then((response)=>{
              if(response.status == '200' && response.statusText === 'OK'){
                resolve({
                  status:'success',
                  identify: data['identify'],
                  data: response.data.token
                })
                
              }else{
                console.error(response.body.message)
                reject(response.body.message)
              }
            },(error)=>{
              reject(error)
            })
          })
          return await promise_get;
          break;
        case 'PUT':
          let promise_put = new Promise(async(resolve,reject) => {
            this._vm.$http.put(`${HOST}${data.route}`,data.data,{
              headers:{
                'Content-type':'multipart/form-data',
                'Authorization' : `Bearer ${state.token}`
              },
              emulateJSON:true
            }).then((response)=>{
              if(response.status == '200' && response.statusText === 'OK'){
                resolve({
                  status:'success',
                  identify: data['identify'],
                  data: response.data.token
                })
              }else{
                console.error(response.body.message)
                reject(response.body.message)
              }
            },(error)=>{
              reject(error)
            })
          })
          return await promise_put;
          break;
        case 'DELETE':
          let promise_delete = new Promise(async(resolve,reject) => {
            this._vm.$http.delete(`${HOST}${data.route}`,data.data,{
              headers:{
                'Content-type':'multipart/form-data',
                'Authorization' : `Bearer ${state.token}`
              },
              emulateJSON:true
            }).then((response)=>{
              if(response.status == '200' && response.statusText === 'OK'){
                resolve({
                  status:'success',
                  identify: data['identify'],
                  data: response.data.token
                })
              }else{
                console.error(response.body.message)
                reject(response.body.message)
              }
            },(error)=>{
              reject(error)
            })
          })
          return await promise_delete;
          break;
      }
    }
  },
  modules: {
  }
})
