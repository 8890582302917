<template>
    <div class="col-md-12" style="min-height:30px;margin-bottom:10px">
        <template v-for="(win,index) in data">
            <div clas="no_sele" @click="$emit('clickWindow',{data:win})" :key="index" class="col-sm-12 col-md-12 col-lg-2 col-xl-2" :style="`${win.active ? 'border-radius:5px 5px 0 0;border-bottom:3px solid purple;' : ''};height:100%;padding:5px;cursor:pointer;text-align:center`">
                <span style="font-size:15px">{{win.label}}</span>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props:['data']
}
</script>