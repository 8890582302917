<template>
    <div class="left-sidebar-pro" :style="`${menuReducemb ? 'display:block !important' : ''}`">
        <nav id="sidebar" :class="`${menuReduce ? 'active' : ''}`" style="max-width: 200px;top: 0;">
            <i v-if="st.windowSizes.width < 1021" class="material-icons" style="right:0;position:absolute;color:black;top:5px;z-index:100;cursor:pointer;" @click="$emit('closeMenu')">chevron_left</i>
            <div class="sidebar-header">
                <a><img class="main-logo" src="@/assets/images/logo/logo.png" alt="" /></a>
                <strong><a><img src="@/assets/images/logo/logosn.png" alt="" /></a></strong>
            </div>
            <nav class="sidebar-nav left-sidebar-menu-pro">
                <ul class="metismenu" id="menu1">
                    <template v-for="(m,index_m) in menu">
                        <li :key="index_m">
                            <a @click="m.colapse = !m.colapse;m.sub_menu.length === 0 ? pushRoutes_b(m) : closeColapse(m)" :class="`text-truncate ${m.sub_menu.length > 0 ? 'has-arrow' : ''} ${m.colapse ? 'active' : 'false'} no_sele`" :style="`${m.active ? 'background:#F6F6F6' : ''};cursor:pointer`" :content="m.label" v-tippy="{arrow:true,placement : 'right'}" :aria-expanded="m.colapse">
                                <span :class="`educate-icon ${m.icon} ${!menuReduce ? 'icon-wrap' : 'icon-wrap_menu_reduce'}`"></span>
                                <span v-show="!menuReduce" class="nav_letter_color">{{m.label}}</span>
                            </a>
                            <template v-if="m.sub_menu.length > 0">
                                <ul v-show="m.colapse" :class="`submenu-angle `" :aria-expanded="m.colapse">
                                    <template v-for="(sub_m,index_sub) in m.sub_menu">
                                        <li :key="index_sub">
                                            <a @click="pushRoutes_subs(sub_m)" :content="sub_m.label" v-tippy="{arrow:true}" :title="sub_m.label" class="text-truncate" :style="`${sub_m.active ? 'background:#F6F6F6' : ''};cursor:pointer`">
                                                <span :class="`educate-icon ${sub_m.icon}`" style="margin-right:5px"></span>
                                                <span class="nav_letter_color" style="font-size:14px">{{sub_m.label}}</span>
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </li>
                    </template>
                </ul>
            </nav>
        </nav>
    </div>
</template>

<script>
export default {
    props:['st','menuReduce','menuReducemb'],
    data(){
        return{
            menu:[
                /*{
                    icon:'educate-student',
                    label:'Students',
                    active:false,
                    colapse:false,
                    route:'/',
                    sub_menu:[]
                }*/
            ]
        }
    },
    watch:{
        '$route':{
            handler(){
                for(const m in this.menu){
                    for(const m2 in this.menu[m].sub_menu){
                        //console.log(this.menu[m].sub_menu[m2].route)
                        if(this.menu[m].sub_menu[m2].route === this.$route.name){
                            this.menu[m].sub_menu[m2].active = true
                        }else{
                            this.menu[m].sub_menu[m2].active = false
                        }
                    }
                }
            }
        }
    },
    methods:{
        pushRoutes_b(data){
            //console.log(data)
            this.menu[0].active = true
            this.$emit('routePushing',data)
        },
        closeColapse(data){
            this.menu[0].active = false
            for(const i in this.menu){
                if(`${data.id}` !== `${this.menu[i].id}`){
                    this.menu[i].colapse = false
                }
            }
        },
        pushRoutes_subs(data){
            this.$emit('routePushing',data)
        },
        change_menu(){
            let user = this.st.personLog;

            this.menu.push(
                {
                    id:9999,
                    icon:'educate-home',
                    label:'Inicio',
                    active:false,
                    colapse:false,
                    route:'/',
                    sub_menu:[]
                }
            )

            let hash = {};
            var  repetios = user.sub.privilegees.filter(o => hash[o.aemenu_id] ? false : hash[o.aemenu_id] = true);

            for(const r in repetios){
                this.menu.push(
                    {
                        id:r,
                        icon:repetios[r].aemenu_icono,
                        label:repetios[r].aemenu_nombre,
                        active:false,
                        colapse:false,
                        route:'',
                        sub_menu:[]
                    }
                )
            }

            for(const i in user.sub.privilegees){
                for(const r in repetios){
                    if(`${user.sub.privilegees[i].aemenu_id}` === `${repetios[r].aemenu_id}`){
                        this.menu[parseInt(r)+1].sub_menu.push({
                            active:false,
                            label:user.sub.privilegees[i].aeopcmenu_nombre,
                            route:user.sub.privilegees[i].aeopcmenu_enlace,
                            icon:user.sub.privilegees[i].aeopcmenu_icono
                        })
                        //console.log(user.sub.privilegees[i])
                    }
                }
            }


            for(const m in this.menu){
                for(const m2 in this.menu[m].sub_menu){
                    //console.log(this.menu[m].sub_menu[m2].route)
                    if(this.menu[m].sub_menu[m2].route === this.$route.name){
                        this.menu[m].sub_menu[m2].active = true
                    }else{
                        this.menu[m].sub_menu[m2].active = false
                    }
                }
            }
        }
    },
    beforeMount(){
        this.change_menu();
    }
}
</script>