<template>
    <div class="col-sm-12" style="height:100%;border-radius:5px;padding:10px;position:relative">
        <div class="col-sm-6" style="margin-bottom:10px;padding:0">
            <div class="col-md-12 col-lg-12 col-xl-12" style="padding:0">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-1 col-xl-1" style="padding:12px 0">
                        <label class="login2">Filas</label>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-9 col-xs-12" style="margin-bottom:10px;padding:0">
                        <div class="form-select-list">
                            <select class="form-control custom-select-value" name="account" v-model="selected_row">
                                <option v-for="(r,index) in rows" :key="index" :value="r">{{r}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="margin-bottom:10px;padding:0;">
            <div class="col-md-12" style="padding:0;position:relative">
                <i class="material-icons" style="position:absolute;color:#DBDBDB;top:8px;left:5px">search</i>
                <input type="text" class="form-control" placeholder="Buscar" style="padding:0 30px" />
            </div>
        </div>
        <div ref="structura_scroll" @mouseup="cancelMoveScroll($event)" @mouseleave="cancelMoveScroll($event)" @mousedown="moveScroll_down($event)" class="col-sm-12" style="display:flex;overflow:auto hidden !important;padding:0;width:100%;user-select: none;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;">
            <div ref="width_scroll" style="display:flex;min-height: auto !important;">
                <template v-for="(hd,index_hd) in data_selected.head">
                    <vue-resizable
                        :key="index_hd"
                        :min-width="data_selected.width && data_selected.width[index_hd] && index_hd !== 0  ? data_selected.width[index_hd] : 70"
                        :width="data_selected.width && data_selected.width[index_hd] && index_hd !== 0  ? data_selected.width[index_hd] : 70"
                        @resize:start="resize_activation_propague = true"
                        @resize:end="resize_activation_propague = false"
                        :active="['r']"
                        :style="`min-width:${data_selected.width && data_selected.width[index_hd] && index_hd !== 0  ? data_selected.width[index_hd] : '70'}px !important;height: auto !important;padding:0;${index_hd == (data_selected['head'].length - 1) ? 'border-right:1px solid #DBDBDB' : ''};border-bottom:1px solid #DBDBDB;border-left:1px solid #DBDBDB;border-top:1px solid #DBDBDB;position:relative`"
                    >
                        <div  class="col-sm-12" :style="`top:${top_head}px;position:absolute;left:0 !important;padding:0;width:100%;z-index:100`">
                            <div class="col-sm-12 text-truncate" :style="`background:rgb(44, 118, 149);width:100%;border-bottom:1px solid #D6D6D6;height:40px;position:sticky !important;top:0px;text-align:center;padding:10px !important;`">
                                <span style="font-weight:500;color:white">{{hd}}</span>
                            </div>
                        </div>
                        <div class="col-sm-12 container_rows" style="padding:0;margin-top:40px">
                            <template v-for="(body,inx_bo) in data_selected.rows">
                                <div :key="inx_bo" @mouseover="hover_row(inx_bo)" @mouseleave="hover_row(null,$event)" :class="`col-sm-12 text-truncate${hover_r == inx_bo ? ' row_active' : ''}`" :style="`padding:0;${inx_bo != (data_selected.rows.length - 1) ? 'border-bottom:1px solid #D6D6D6;' : ''}height:40px;position:sticky !important;top:0px;text-align:center;${!Array.isArray(body[index_hd]) ? 'padding:10px 10px 5px 10px !important' : 'padding:7px 10px 5px 10px !important;'}`">
                                    <template v-if="!Array.isArray(body[index_hd])">
                                        <template v-if="data_selected.link && data_selected.link[index_hd]">
                                            <a :href="body[index_hd]" target="_blank">
                                                <span style="color:blue">{{body[index_hd]}}</span>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span>{{body[index_hd]}}</span>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="col-sm-12">
                                            <button v-if="body[index_hd][0]" class="btn success" @click="$emit('edit',body.allData)" style="padding:5px 6px 0 6px;position:relative;">
                                                <i class="material-icons" style="font-size:15px">edit</i>
                                            </button>
                                            <button v-if="body[index_hd][1]" class="btn danger" @click="$emit('delete',body.allData)" style="padding:5px 6px 0 6px;position:relative;margin-left:5px">
                                                <i class="material-icons" style="font-size:15px">delete</i>
                                            </button>
                                            <button v-if="body[index_hd][2]" class="btn btn-primary" @click="$emit('sendEmail',body.allData)" style="padding:5px 6px 0 6px;position:relative;margin-left:5px" :content="`Reenviar contraseña`" v-tippy="{arrow:true}">
                                                <i class="material-icons" style="font-size:15px">mail</i>
                                            </button>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </vue-resizable>
                </template>
            </div>
        </div>
        <div class="col-md-12" style="position:relative;min-height:30px">
            <div class="text-center" style="position:absolute;transform:translate(-50%,-50%);top:70%;left:50%;"><!--width: 100%;overflow-x: auto;overflow-y: hidden;-->
                <template v-for="(c,inx_cant) in pages_arr">
                    <span v-if="inx_cant < 10" :key="inx_cant" :class="`pagination_span ${`${page_selected.page}` == `${c.page}` ? 'active' : ''} no_sele`" @click="change_page(c)">
                        <span style="padding:2px">{{c.page}}</span>
                    </span>
                </template>
                <template v-if="pages_arr.length > 10">
                    <span style="font-weight:500">.....</span>
                    <span v-if="pages_arr.length > 10" :class="`pagination_span no_sele`"  @click="change_page(pages_arr[pages_arr.length-1])">
                        <span style="padding:2px">{{pages_arr[pages_arr.length-1].page}}</span>
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>
<script>

import VdtnetTable from 'vue-datatables-net'
import VueResizable from 'vue-resizable'

export default {
    props:['st','head','dataT'],
    data(){
        return{
            top_head:0,
            calculos: 0,
            resize_activation_propague:false,
            move:false,
            copy_position_init: '',
            position_init:'',
            settime_width:null,
            top:'',
            left:'',
            hover_r: null,
            rows:[5,10,20,30,50],
            data_selected:{},
            selected_row:30,
            pages:0,
            pages_arr:[],
            page_selected:{
                page:1
            }
        }
    },
    components:{
        VdtnetTable,
        VueResizable
    },
    watch:{
        'st.scroll_to':{
            handler(){
                //console.log(this.$refs['structura_scroll'])
                if(this.$refs['structura_scroll'] != undefined){
                    this.calculos = this.$refs['structura_scroll'].getBoundingClientRect().top;
                    if(this.calculos < 0){
                        this.top_head = (this.calculos * -1) + 60;
                    }else{
                        this.top_head = 0;
                    }
                }
            },deep: true
        },
        'st.windowSizes':{
            handler(){
                this.actualizar_width();
            },deep: true
        },
        selected_row:{
            handler(){
                this.init();
            }
        },
        'dataT.rows':{
            handler(){
                //console.log(this.page_selected)
                this.data_selected = this.dataT
                /*for(let i = this.page_selected.init; i < this.page_selected.finish; i++){
                    if(this.dataT.rows[i]){
                        this.data_selected.rows.push(this.dataT.rows[i])
                    }
                }*/

                /*let arr = JSON.parse(JSON.stringify(this.data_selected));
                this.data_selected = {};
                this.data_selected = arr;

                console.log(this.data_selected)*/
            },deep:true
        }
    },
    methods:{
        moveScroll_down(e){
            setTimeout(()=>{
                if(!this.resize_activation_propague && e.button == 0){
                    e.preventDefault()
                    this.move = true;
                    this.position_init = this.$refs['structura_scroll'].scrollLeft;
                    this.copy_position_init = e.clientX;
                    document.onmousemove = this.moverScroll;
                }
            },1)
        },
        moverScroll(e){
            if(this.move && !this.resize_activation_propague){
                this.$refs['structura_scroll'].scrollLeft = this.position_init + this.copy_position_init - e.clientX;
            }
        },
        cancelMoveScroll(e){
            e.preventDefault();
            this.move = false;
        },
        hover_row(id,event){
            if(id === null){
                if(event.relatedTarget.className != 'resizable-r'){
                    this.hover_r = null;
                }
            }else{
                this.hover_r = id;
            }
        },
        change_page(data){
            this.page_selected = data
            this.data_selected['head'] = this.dataT.head
            this.actualizar_width();
            this.data_selected['link'] = this.dataT.link
            this.data_selected['rows'] = []
            for(let i = data.init; i < data.finish; i++){
                if(this.dataT.rows[i]){
                    this.data_selected.rows.push(this.dataT.rows[i])
                }
            }
        },
        actualizar_width(){
            var ancho = window.innerWidth - 108;
            var width_for_one = ancho / 6; 
            var array = Array(6).fill(width_for_one <= 100 ? 100 : width_for_one);
            this.data_selected['width'] = [];
            this.data_selected['width'] = array;
        },
        init(){
            this.data_selected = []
            let dt = [];
            this.pages_arr = []
            dt = this.dataT['rows']
            this.data_selected['head'] = this.dataT.head
            this.actualizar_width();
            this.data_selected['link'] = this.dataT.link
            this.data_selected['rows'] = []
            for(let i = 0; i < this.selected_row; i++){
                if(this.dataT.rows[i]){
                    this.data_selected['rows'].push(this.dataT.rows[i])
                }
            }
            this.pages = parseInt(dt.length)/parseInt(this.selected_row)
            for(let p = 0; p < this.pages; p++){
                this.pages_arr.push({
                    page:p+1,
                    init:parseInt(this.selected_row)*parseInt(p),
                    finish:parseInt(this.selected_row)*(parseInt(p)+1)
                })
            }
            this.page_selected['page'] = 1
        }
    },
    beforeMount(){
        this.init();
    }
}
</script>