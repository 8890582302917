<template>
    <div class="col-sm-12 no_sele" style="padding:0;position:relative">
        <div v-if="init_exam" style="float:right;position:relative">
            <span class="material-icons" style="position:absolute;left:-30px;top:5px">schedule</span>
            <span style="font-weight:500;font-size:25px">{{`${parseInt(hor) < 10 && parseInt(hor) > 1 ? '0'+hor : hor}:${parseInt(min) < 10 && parseInt(min) > 1  ? '0'+min : min}:${parseInt(seg) < 10 && parseInt(seg) > 1 ? '0'+seg : seg}`}}</span>
        </div>
        <template v-for="(ex,index) in data.questions">
            <div :key="index" :style="`${index !== 0 ? 'border-top:2px solid #DBDBDB;' : ''}padding:10px 0;width:100%;float:left;`">
                <div class="col-sm-12" style="margin-bottom:10px">
                    <div class="col-md-12 ql-editor ql-blank" v-html="ex.title" style="min-height:auto !important;padding:0;"></div>
                    <!--<span style="font-weight:500;font-size:20px">{{index+1}}. {{ex.title}}</span>-->
                </div>
                <div class="col-sm-12" style="margin-bottom:10px">
                    <template v-if="`${ex.tipo_pregunta}` === '5'">
                        <template v-for="(boolean,inx_bol) in ex.opciones">
                            <div :key="inx_bol">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="i-checks pull-left">
                                            <label>
                                                <input type="radio" value="" :name="`boolean_${index}`" @click="selectBoolean(ex,boolean)">
                                                <span style="padding-left:10px;font-weight:400">{{boolean.aeopcres_descripcion}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-if="`${ex.tipo_pregunta}` == '2'">
                        <template v-for="(multiple,inx_mul) in ex.opciones">
                            <div :key="inx_mul">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="i-checks pull-left">
                                            <label>
                                                <input type="checkbox" value="" @click="selectMultiple(ex,multiple)">
                                                <span style="padding-left:10px;font-weight:400">{{multiple.aeopcres_descripcion}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-if="`${ex.tipo_pregunta}` == '1'">
                        <template v-for="(multiple,inx_mul) in ex.opciones">
                            <div :key="inx_mul">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="i-checks pull-left">
                                            <label>
                                                <input type="radio" value="" :name="`multiple_1`" @click="selectMultiple(ex,multiple)">
                                                <span style="padding-left:10px;font-weight:400">{{multiple.aeopcres_descripcion}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </template>
        <button class="btn success loginbtn" style="float:right;width:200px;margin-right:15px;position:sticky;bottom:20px;margin-top:10px" @click="sendExam">Terminar</button>
    </div>
</template>

<script>
export default {
    props:['data'],
    data(){
        return{
            init_exam:false,
            seg:'',
            min:'',
            hor:'',
            intervar:'',
            answers:[],
            multiple_selected:[]
        }
    },
    watch:{
        answers:{
            handler(){
                this.$emit('percentage',{
                    id_exam:this.data.id,
                    data:this.answers
                })
            },deep:true
        },
        data:{
            handler(){
                this.init();
            },deep:true
        }
    },
    methods: {
        selectBoolean(ex,answer){
            let q = false;
            if(this.answers.length === 0){
                this.answers.push({
                    question:ex,
                    answer: answer
                })
            }else{
                for(const i in this.answers){
                    if(this.answers[i].question.id_pregunta == ex.id_pregunta){
                        this.answers[i].answer = answer;
                        q = true;
                        break;
                    }
                }

                if(!q){
                    this.answers.push({
                        question:ex,
                        answer: answer
                    })
                }
            }

            let arr = JSON.parse(JSON.stringify(this.answers));
            this.answers = [];
            this.answers = arr;
        },
        selectMultiple(ex,answer){
            let q = false;
            if(this.multiple_selected.length === 0){
                this.multiple_selected.push(answer)
            }else{
                for(const i in this.multiple_selected){
                    if(`${answer.aeopcres_id}` === `${this.multiple_selected[i].aeopcres_id}`){
                        this.multiple_selected.splice(i,1)
                        q = true;
                        break;
                    }
                }

                if(!q){
                    this.multiple_selected.push(answer)
                }
            }

            //console.log(this.multiple_selected)

            let a = false;
            if(this.answers.length === 0){
                this.answers.push({
                    question:ex,
                    answer: this.multiple_selected
                })
            }else{
                for(const i in this.answers){
                    if(this.answers[i].question.id_pregunta == ex.id_pregunta){
                        this.answers[i].answer = this.multiple_selected;
                        a = true;
                        break;
                    }
                }

                if(!a){
                    this.answers.push({
                        question:ex,
                        answer: answer
                    })
                }
            }

            let arr = JSON.parse(JSON.stringify(this.answers));
            this.answers = [];
            this.answers = arr;
        },
        sendExam(){
            clearInterval(this.intervar)
            //console.log(this.answers)
            this.$emit('sendExam',{
                id_exam:this.data.id,
                duration:`${parseInt(this.hor) < 10 && parseInt(this.hor) > 1 ? '0'+this.hor : this.hor}:${parseInt(this.min) < 10 && parseInt(this.min) > 1  ? '0'+this.min : this.min}:${parseInt(this.seg) < 10 && parseInt(this.seg) > 1 ? '0'+this.seg : this.seg}`,
                answers:this.answers
            })
        },
        timeExam(){
            let time = this.data.duracion.split(':');
            this.hor = time[0]
            this.min = time[1]
            this.seg = time[2]

            this.intervar = setInterval(()=>{
                if(this.seg === 0 || this.seg === '00'){
                    this.min = parseInt(this.min)-1;
                    this.seg = '59'

                    if(this.min < 1 && this.hor > 0){
                        this.min = '59'
                        this.hor = parseInt(this.hor)-1
                    }

                }

                this.seg--

                if(this.hor === 0 && this.min === -1){
                    this.hor = 0
                    this.min = 0
                    this.seg = 0
                    clearInterval(this.intervar)
                    this.sendExam()
                }
            },1000)
        },
        init(){
            this.$swal({
                title: '¿Estás seguro/a de que desea iniciar este examen?',
                text: "Una vez iniciado debera terminarlo antes del tiempo limite o se enviará automaticamente",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, comenzar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.init_exam = true
                    this.timeExam()

                    this.answers = [];
                    //console.log(this.data)
                    for(const i in this.data.questions){
                        this.answers.push({
                            question:{
                                id_cuestions: this.data.questions[i].id_cuestions,
                                id_pregunta: this.data.questions[i].id_pregunta,
                                nombre: this.data.questions[i].nombre,
                                orden: this.data.questions[i].orden,
                                tipo_pregunta: this.data.questions[i].tipo_pregunta,
                                title: this.data.questions[i].title
                            }
                        })
                    }
                }else{
                    this.$emit('noInitExam')
                }
            })
        }
    },
    beforeMount(){
        console.log(this.data)
        this.init();
    },
}
</script>