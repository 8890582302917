<template>
    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
            <div class="all-form-element-inner col-sm-12" style="position:relative;padding: 18px;border-radius:5px;min-height: calc(100vh - 290px);">
                <div class="col-sm-12" style="margin-bottom:10px;padding:0">
                    <div class="col-sm-12" style="margin-bottom:10px;">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="login2 pull-right pull-right-pro"><span style="color:red">*</span>Grupo</label>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <div class="form-select-list">
                                    <select class="form-control custom-select-value" name="account" v-model="seletec" @change="consultar">
                                        <template v-for="(dt,index) in obj">
                                            <option :key="index" :value="dt.value">{{dt.label}}</option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-sm-12" style="padding:0">
                        <button class="btn success loginbtn" style="float:right;width:200px;margin-left:5px;" @click="consultar">Consultar</button>
                        <button class="btn danger loginbtn" style="float:right;width:200px">Cancelar</button>
                    </div>-->
                </div>
                <Tables
                    v-if="show"
                    :st="st"
                    :dataT="send_horario"
                    @edit="editHorario"
                    @delete="deleteHorario"
                />
            </div>
              <!--{{horario}}-->
        </div>
    </div>
</template>

<script>
import Tables from '@/components/Scheduler/Tables.vue'
export default {
    props:['horario'],
    components:{
        Tables
    },
    data(){
        return{
            seletec:'1',
            show:false,
            send_horario:{
                head:['Hora','Docente','Asignatura','enlace','Acciones'],
                link:[false,false,false,true,false],
                width:[100,'','',280,''],
                rows:[]
            },
            obj:[
                {
                    value:1,
                    label:1
                },
                {
                    value:2,
                    label:2
                },
                {
                    value:3,
                    label:3
                },
                {
                    value:4,
                    label:4
                },
                {
                    value:5,
                    label:5
                },
                {
                    value:6,
                    label:6
                },
                {
                    value:7,
                    label:7
                },
                {
                    value:8,
                    label:8
                },
                {
                    value:9,
                    label:9
                },
                {
                    value:10,
                    label:10
                },
                {
                    value:11,
                    label:11
                }
            ]
        }
    },
    watch:{
        horario:{
            handler(){
                //console.log(this.horario)
                this.show = true

                let response = this.horario

                this.send_horario = {
                    head:['Hora','Docente','Asignatura','Enlace','Acciones'],
                    width:[100,200,'',280,110],
                    link:[false,false,false,true,false],
                    rows:[]
                }

                for(const i in response){
                    this.send_horario.rows.push(
                        {
                            0:response[i].aeasignaciones_hora,
                            1:response[i].docente,
                            2:response[i].aeasignaciones_asignatura,
                            3:response[i].aeasignaciones_enlace,
                            4:[true,true,false],
                            allData:response[i]
                        }
                    )
                }

                //console.log('puta',this.send_horario)
            },deep:true
        }
    },
    methods: {
        consultar(){
            if(this.seletec){
                this.$emit('consultar',this.seletec)
                this.show = false
            }else{
                this.$swal(
                    {
                        icon: 'error',
                        title: 'Campos vacios',
                        text: `Ops... Parece que hay campos vacios, verifica`,
                    }
                );
            }
        },
        editHorario(data){
            this.$emit('edit',data)
        },
        deleteHorario(data){
            this.$emit('delete',data)
        }
    },
    beforeMount(){
    }
}
</script>