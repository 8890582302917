<template>
    <div>
        <FichasComponent
            v-if="(`${st.personLog.sub.datos_usuario.rol}` === '1' || `${st.personLog.sub.datos_usuario.rol}` == '3') && !init_exam"
            :data="fichas"
            @buttonsActions="buttonsActionsCards"
        />
        <div class="col-sm-12" style="margin-top:15px">
            <template v-if="`${st.personLog.sub.datos_usuario.rol}` === '1'">
                <div v-if="!init_exam" class="col-sm-12" style="padding:0 0 20px 0">
                    <!--<CreateQuestions
                        :st="st"
                        @createExam="createExam"
                    />-->
                    <CreateExam
                        :st="st"
                        @createExam="createExam"
                        :groups="groups"
                        :typeIntentos="typeIntentos"
                        :loadRegister="loadRegister"
                        :dataExams="dataExams"
                        @createQuestions="createQuestions"
                    />
                </div>
            </template>
            <template v-else>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: calc(100vh - 218px);max-height:auto;background: white;">
                    <div class="col-sm-12 sparkline12-list">
                        <div class="sparkline12-hd col-sm-12" style="padding:0">
                            <template v-if="`${st.personLog.sub.datos_usuario.rol}` === '2'">
                                <Windows
                                    :data="windows"
                                    @clickWindow="clickWindow"
                                />
                                <CreateHorario
                                    v-if="windows[0].active"
                                    :st="st"
                                    @buttonActions="buttonActionsForm"
                                    :dataHorario="dataHorario"
                                    :loadRegister="loadRegister"
                                    :docents="data_teachers"
                                />
                                <Horarios
                                    v-if="windows[1].active"
                                    :st="st"
                                    :dataT="datos"
                                    @buttonActions="buttonActionsTable"
                                    :selectGroup="true"
                                    :selectStudent="false"
                                    @sendGroup="consultar"
                                    :groups="groups"
                                    :delete_h="delete_h"
                                    @changeDeleteh="delete_h = false"
                                />
                            </template>
                            <template v-else-if="`${st.personLog.sub.datos_usuario.rol}` === '3' || `${st.personLog.sub.datos_usuario.rol}` === '6'">
                                <template v-if="!init_exam">
                                    <Windows
                                        :data="windows"
                                        @clickWindow="''"
                                    />
                                    <Horarios
                                        v-if="windows[0].active"
                                        :st="st"
                                        :dataT="datos"
                                        @buttonActions="''"
                                        :selectGroup="false"
                                        :selectStudent="false"
                                        @sendGroup="''"
                                    />
                                </template>
                            </template>

                        </div>
                    </div>
                </div>
            </template>

            <div v-if="init_exam" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: calc(100vh - 218px);max-height:auto;background: white;">
                <div class="col-sm-12 sparkline12-list">
                    <div class="sparkline12-hd col-sm-12" style="padding:0">

                        <Exams
                            v-if="init_exam"
                            :data="exam_data"
                            @noInitExam="cancelExam"
                            @sendExam="sendExam"
                            @percentage="percentage"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!--Hola Education-->
    </div>
</template>

<script>
    import FichasComponent from '@/components/Fichas/Fichas.vue'
    import Windows from '@/components/Scheduler/Windows.vue'
    import Horarios from '@/components/Scheduler/horarios.vue'
    import CreateHorario from '@/views/asignments/components/createHorario.vue'
    import Exams from '@/components/Exams/Exams.vue'
    import CreateQuestions from '@/components/Exams/CreateQuestions.vue'
    import CreateExam from '@/components/Exams/CreateExam.vue'
    export default {
        props:['st','outPutBackend'],
        data(){
            return{
                windows:[],
                init_exam:false,
                exam_data:[],
                fichas:[
                    /*{
                        title:'Evaluación: Matematicas, 1er periodo',
                        value:'',
                        percentage:'',
                        button:{
                            case:'create_exams',
                            type:'success',
                            title:'Crear una evaluación'
                        },
                        images:[
                            {
                                img:require('@/assets/images/students.jpg'),
                                text: ''
                            },
                            {
                                img:require('@/assets/images/students.jpg'),
                                text: 'si cambie'
                            }
                        ]
                    }*/
                ],
                datos:{
                    head:['Hora','Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'],
                    width:[80,'',300,300,300,300,300,300],
                    rows:[]
                },
                dataHorario:{},
                loadRegister:false,
                data_teachers:[],
                groups:[],
                typeIntentos:[],
                delete_h:false,
                dataExams:[]
            }
        },
        components:{
            FichasComponent,
            Windows,
            Horarios,
            CreateHorario,
            Exams,
            CreateExam,
            CreateQuestions
        },
        watch:{
            outPutBackend:{
                handler(){
                    let response = this.outPutBackend
                    switch(response.identify){
                        case 'new_horario':
                           //console.log(response);

                            this.consultar({group:this.group_act ? this.group_act : 1})
                            this.loadRegister = false

                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Registro exitoso',
                                    text: `Se registro correctamente el nuevo horario`,
                                }
                            );
                        break;
                        case 'updateAsignments':
                            //console.log('edit_user',response)

                            this.consultar({group:this.group_act ? this.group_act : 1})

                            this.loadRegister = false

                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Registro exitoso',
                                    text: `Se actualizó correctamente el nuevo horario`,
                                }
                            );
                        break;
                        case 'delete_horario':
                            //this.returnTeachers();

                            this.delete_h = true

                            this.consultar({group:this.group_act ? this.group_act : 1})

                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Eliminación con exito',
                                    text: `Se edito eliminó el horario correctamente`,
                                }
                            );
                        break;
                        case 'select_horario':
                            //console.log('response',response)
                            this.datos.rows = []
                            let arr = []
                            let ids = Date.now();
                            for(const i in response.data.sub){
                                arr.push({
                                    0:`${response.data.sub[i].row_to_json.aeasignaciones_hora}_${ids+i}`
                                })
                            }

                            for(const u in arr){
                                for(const i in response.data.sub){
                                    if(`${response.data.sub[i].row_to_json.aeasignaciones_hora}_${ids+u}` === `${arr[u][0]}`){
                                        for(const s in response.data.sub[i].row_to_json.detalles){
                                            arr[u][response.data.sub[i].row_to_json.detalles[s].aeasignaciones_dia] = {
                                                id:response.data.sub[i].row_to_json.detalles[s].aeasignaciones_id,
                                                id_docente:response.data.sub[i].row_to_json.detalles[s].aedocentes_id,
                                                asignatura:response.data.sub[i].row_to_json.detalles[s].aeasignaciones_asignatura,
                                                enlace:response.data.sub[i].row_to_json.detalles[s].aeasignaciones_enlace,
                                                docente:response.data.sub[i].row_to_json.detalles[s].docente
                                            }
                                        }
                                    }
                                }
                            }

                            //console.log(arr)

                            this.datos.rows = arr

                            let arr2 = JSON.parse(JSON.stringify(this.datos))
                            this.datos = {}
                            this.datos = arr2

                            if(`${this.st.personLog.sub.datos_usuario.rol}` == '3'){
                                this.windows[0].active = true
                            }
                        break;
                        case 'consultExams':
                            let dt = response.data.sub

                            console.log('examenes',dt)

                            this.fichas = []

                            for(const i in dt){
                                if(dt[i].questions && dt[i].questions.length > 0){
                                    this.fichas.push(
                                        {
                                            id:dt[i].id,
                                            title:dt[i].title,
                                            value:'',
                                            percentage:'0',
                                            button:{
                                                case:'init_exam',
                                                type:'success',
                                                title:'Iniciar examen'
                                            },
                                            questions:dt[i],
                                            images:[
                                                /*{
                                                    img:require('@/assets/images/students.jpg'),
                                                    text: ''
                                                },
                                                {
                                                    img:require('@/assets/images/students.jpg'),
                                                    text: 'si cambie'
                                                }*/
                                            ]
                                        }
                                    )
                                }
                            }
                        break;
                        case 'allteachers':
                            this.data_teachers = []
                            for(const i in response.data.sub){
                                this.data_teachers.push(
                                    {
                                        id:response.data.sub[i].aeusu_id,
                                        name:response.data.sub[i].aedocentes_nombres,
                                        last_name:response.data.sub[i].aedocentes_apellidos,
                                        identification:response.data.sub[i].aeusu_llave,
                                        phone:response.data.sub[i].aedocentes_telefono,
                                        email:response.data.sub[i].aedocentes_mail,
                                        date_birth:response.data.sub[i].aedocentes_fechanacimiento,
                                        gender:response.data.sub[i].aedocentes_genero,
                                        direction:response.data.sub[i].aedocentes_direccion
                                    }
                                )
                            }
                        break;
                        case 'calificate_exam':
                            //console.log(response)
                            this.$swal(
                                {
                                    icon: 'success',
                                    title: `Examen terminado, Promedio: ${response.data.sub.porcentaje}`,
                                    text: `Felicitaciones, terminaste el examen con exito, obtuviste ${response.data.sub.preguntas_correctas} correctas de ${response.data.sub.total_preguntas} preguntas que tiene el examen`,
                                }
                            );
                            if(`${this.st.personLog.sub.datos_usuario.rol}` == '2'){
                                this.windows[0].active = false
                            }else if(`${this.st.personLog.sub.datos_usuario.rol}` == '3'){
                                this.windows[0].active = false
                            }
                            this.init_exam = false
                            this.consultar({group:parseInt(this.st.personLog.sub.user.grupo)})
                            /*if(response.data.sub.aprobate){
                                this.$swal(
                                    {
                                        icon: 'success',
                                        title: `Examen terminado, Calificación: ${response.data.sub.calification}`,
                                        text: `Felicitaciones, terminaste el examen con exito, obtuviste ${response.data.sub.preguntas_correctas} preguntas correctas y ${response.data.sub.preguntas_incorrectas} preguntas incorrectas`,
                                    }
                                );
                            }else{
                                this.$swal(
                                    {
                                        icon: 'warn',
                                        title: `Examen terminado, Calificación: ${response.data.sub.calification}`,
                                        text: `Has perdido el examen, obtuviste ${response.data.sub.preguntas_correctas} preguntas correctas y ${response.data.sub.preguntas_incorrectas} preguntas incorrectas`,
                                    }
                                );
                            }
                            */
                        break;
                        case 'consultGroups':
                            //console.log(response)
                            this.groups = []
                            this.groups = response.data.sub
                        break;
                        case 'typeIntento':
                            this.typeIntentos = []
                            this.typeIntentos = response.data.sub
                            //console.log(response)
                        break;
                        case 'create_exam':
                            //console.log('its ok',response)

                            this.loadRegister = false
                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Registro exitoso',
                                    text: `Se registro correctamente el nuevo examen`,
                                }
                            );

                            this.consultExamsByUser();
                        break;
                        case 'consulExamsbyId':
                            this.dataExams = []
                            this.dataExams = response.data.sub
                        break;
                        case 'create_question':
                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Registro exitoso',
                                    text: `Se registro correctamente una pregunta nueva`,
                                }
                            );
                        break;
                    }
                }
            }
        },
        methods:{
            createQuestions(data){
                console.log(data)

                let formData = new FormData();
                formData.append('id_exam',data.id_exam);
                formData.append('description',data.body.content);
                formData.append('order',data.body.questions_order_selected);
                formData.append('type_question',data.body.type_question.code);
                formData.append('options',JSON.stringify(data.options));

                formData.append('id_institucion',`${this.st.personLog.sub.datos_usuario.id_institucion}`)
                formData.append('id_usu',`${this.st.personLog.sub.datos_usuario.id_usuario}`)
                formData.append('ano_lectivo',`${this.st.personLog.sub.datos_usuario.id_anolectivo}`)
                formData.append('rol',`${this.st.personLog.sub.datos_usuario.rol}`)

                this.$emit('goBackend',{
                    route:'/academic/add_questions',
                    method:'POST',
                    identify:'create_question',
                    data: formData
                })
            },
            createExam(data){
                let groups = [];
                for(const i in data.selected_group){
                    groups.push(data.selected_group[i].code)
                }
                let ids = this.st.personLog.sub.datos_usuario
                let formData = new FormData();
                formData.append('content',data.contenido);
                formData.append('date_init',data.date_init)
                formData.append('date_finish',data.date_finish)
                formData.append('time',data.time)
                formData.append('name_exam',data.name_exam)
                formData.append('file',data.file)
                formData.append('intentos',data.num_try)
                formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                formData.append('id_institucion',`${ids.id_institucion}`)
                formData.append('id_usuario',`${ids.id_usuario}`)
                formData.append('groups',JSON.stringify(groups))
                formData.append('tipo_intento',parseInt(data.selected_typeIntentos.code))
                formData.append('ordenado',data.questions_order_selected.code)
                this.$emit('goBackend',{
                    route:'/academic/addExam',
                    method:'POST',
                    identify:'create_exam',
                    data: formData
                })

                this.loadRegister = true
            },
            percentage(data){
                for(const i in this.fichas){
                    if(`${this.fichas[i].id}` === `${data.id_exam}`){
                        let per = []
                        this.fichas[i].percentage = 0;
                        for(const p in data.data){
                            if(data.data[p].answer){
                                per.push(data.data[p].answer)
                            }
                        }

                        let sum = data.data.length

                        sum = (per.length*100/parseInt(sum))

                        this.fichas[i].percentage = parseInt(sum)
                    }
                }
            },
            sendExam(data){
                let formData = new FormData()
                formData.append('id_exam',parseInt(data.id_exam));
                formData.append('duration',data.duration);
                formData.append('answers',JSON.stringify(data.answers));
                this.$emit('goBackend',{
                    route:'/academic/calificate',
                    method:'POST',
                    identify:'calificate_exam',
                    data: formData
                })
            },
            cancelExam(){
                if(`${this.st.personLog.sub.datos_usuario.rol}` == '2'){
                    this.windows[0].active = false
                }else if(`${this.st.personLog.sub.datos_usuario.rol}` == '3' || `${this.st.personLog.sub.datos_usuario.rol}` == '6'){
                    this.windows[0].active = false
                }
                this.init_exam = false
                this.consultar({group:parseInt(this.st.personLog.sub.user.grupo)})
            },
            buttonActionsForm(caso,dataComponent){
                switch(caso){
                    case 'save_horario':
                        let formDataNewHorario = new FormData();
                        formDataNewHorario.append('institucion_id',dataComponent.data.institucion_id)
                        formDataNewHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                        formDataNewHorario.append('teacher',dataComponent.data.teacher)
                        formDataNewHorario.append('group',dataComponent.data.group)
                        formDataNewHorario.append('day',dataComponent.data.day)
                        formDataNewHorario.append('asignments',dataComponent.data.asignments)
                        formDataNewHorario.append('time',dataComponent.data.time)
                        formDataNewHorario.append('link',dataComponent.data.link)
                        this.$emit('goBackend',{
                            route:'/asignments/registro',
                            method:'POST',
                            identify:'new_horario',
                            data: formDataNewHorario
                        })
                        this.loadRegister = true
                    break;
                    case 'edit_horario':
                        let formDataUptHorario = new FormData();
                        formDataUptHorario.append('institucion_id',dataComponent.data.institucion_id)
                        formDataUptHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                        formDataUptHorario.append('id',dataComponent.data.id)
                        formDataUptHorario.append('teacher',dataComponent.data.teacher)
                        formDataUptHorario.append('group',dataComponent.data.group)
                        formDataUptHorario.append('day',dataComponent.data.day)
                        formDataUptHorario.append('asignments',dataComponent.data.asignments)
                        formDataUptHorario.append('time',dataComponent.data.time)
                        formDataUptHorario.append('link',dataComponent.data.link)
                        this.$emit('goBackend',{
                            route:'/asignments/updateAsignments',
                            method:'POST',
                            identify:'updateAsignments',
                            data: formDataUptHorario
                        })
                        this.loadRegister = true
                    break;
                    case 'delete_horario':
                        console.log(dataComponent)
                    break;
                    case 'uploadCsv':
                        console.log(dataComponent.data)
                    break;
                }
            },
            buttonActionsTable(caso,dataComponent){
                switch(caso){
                    case 'save_horario':
                        let formDataNewHorario = new FormData();
                        formDataNewHorario.append('institucion_id',dataComponent.data.institucion_id)
                        formDataNewHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                        formDataNewHorario.append('teacher',dataComponent.data.teacher)
                        formDataNewHorario.append('group',dataComponent.data.group)
                        formDataNewHorario.append('day',dataComponent.data.day)
                        formDataNewHorario.append('asignments',dataComponent.data.asignments)
                        formDataNewHorario.append('time',dataComponent.data.time)
                        formDataNewHorario.append('link',dataComponent.data.link)
                        this.$emit('goBackend',{
                            route:'/asignments/registro',
                            method:'POST',
                            identify:'new_horario',
                            data: formDataNewHorario
                        })
                        this.loadRegister = true
                    break;
                    case 'edit_horario':
                        this.windows[0].active = true
                        this.windows[1].active = false
                        this.dataHorario = dataComponent
                    break;
                    case 'delete_horario':
                        this.$swal({
                            title: '¿Estás seguro/a de eliminar este horario?',
                            text: "Una vez eliminado no podra recuperar el horario",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Si, Eliminar',
                            cancelButtonText: 'Cancelar'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                console.log(dataComponent)
                                let formDataDelete = new FormData();
                                formDataDelete.append('id',parseInt(dataComponent.data.id));
                                this.$emit('goBackend',{
                                    route:'/asignments/deleteHorario',
                                    method:'POST',
                                    identify:'delete_horario',
                                    data: formDataDelete
                                })
                            }
                        })
                    break;
                    case 'uploadCsv':
                        console.log(dataComponent.data)
                    break;
                }
            },
            buttonActions(caso,dataComponent){
                switch(caso){
                    case 'save_horario':
                        let formDataNewHorario = new FormData();
                        formDataNewHorario.append('institucion_id',dataComponent.data.institucion_id)
                        formDataNewHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                        formDataNewHorario.append('teacher',dataComponent.data.teacher)
                        formDataNewHorario.append('group',dataComponent.data.group)
                        formDataNewHorario.append('day',dataComponent.data.day)
                        formDataNewHorario.append('asignments',dataComponent.data.asignments)
                        formDataNewHorario.append('time',dataComponent.data.time)
                        formDataNewHorario.append('link',dataComponent.data.link)
                        this.$emit('goBackend',{
                            route:'/asignments/registro',
                            method:'POST',
                            identify:'new_horario',
                            data: formDataNewHorario
                        })
                        this.loadRegister = true
                    break;
                    case 'edit_horario':
                        this.windows[0].active = true
                        this.windows[1].active = false
                        this.dataHorario = dataComponent
                    break;
                    case 'delete_horario':
                        console.log(dataComponent)
                    break;
                    case 'uploadCsv':
                        console.log(dataComponent.data)
                    break;
                }
            },
            buttonsActionsCards(data){
                switch(data.case){
                    case 'init_exam':
                        this.exam_data = {}
                        this.exam_data = data.data
                        if(`${this.st.personLog.sub.datos_usuario.rol}` == '2'){
                            this.windows[0].active = false
                        }
                        this.init_exam = true
                    break;
                }
            },
            clickWindow(data){
                for(const i in this.windows){
                    if(`${data.data.id}` === `${this.windows[i].id}`){
                        this.windows[i].active = true
                    }else{
                        this.windows[i].active = false
                    }
                }
                if(data.data.id === 1){
                    this.loadRegister = false
                }
                if(this.windows[1].active){
                    this.consultar({group:1})
                }
            },
            consultar(data){
                this.datos.rows = []
                let ids = this.st.personLog.sub.datos_usuario;
                this.group_act = data.group
                let formData = new FormData();
                formData.append('group',`${data.group}`)
                formData.append('id',`${ids.idacademico}`)
                formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                formData.append('id_institucion',`${ids.id_institucion}`)
                let ruta = `${this.st.personLog.sub.datos_usuario.rol}` == '1' ? 'horario' : `${this.st.personLog.sub.datos_usuario.rol}` == '2' ? 'horarioTeachers' : 'horario'
                this.$emit('goBackend',{
                    route:`/asignments/${ruta}`,
                    method:'POST',
                    identify:'select_horario',
                    data: formData
                })
            },
            returnTeachers(){
                let formData = new FormData();
                formData.append('id_institucion',`${this.st.personLog.sub.datos_usuario.id_institucion}`)
                formData.append('id_usu',`${this.st.personLog.sub.datos_usuario.id_usuario}`)
                formData.append('ano_lectivo',`${this.st.personLog.sub.datos_usuario.id_anolectivo}`)
                formData.append('rol',`${this.st.personLog.sub.datos_usuario.rol}`)
                this.instituto_rol = true
                this.$emit('goBackend',{
                    route:'/teachers/allteachers',
                    method:'POST',
                    identify:'allteachers',
                    data: formData
                })
            },
            consultExams(){
                let dt = ["7","7","7","7","7","7","7","7","9","8","1","2","11","6","Cuarto","Octavo","7","2","1","10","11","10","Décimo 1","Décimo 2","Octavo","Primero"]
                let ids = this.st.personLog.sub.datos_usuario

                let formData = new FormData();
                formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                formData.append('id_institucion',`${ids.id_institucion}`)
                //formData.append('group',dt[i])
                this.$emit('goBackend',{
                    route:`/academic/consultExams`,
                    method:'POST',
                    identify:'consultExams',
                    data: formData
                })

                /*for(const i in dt){
                    let formData = new FormData();
                    formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                    formData.append('id_institucion',`${ids.id_institucion}`)
                    formData.append('group',dt[i])
                    this.$emit('goBackend',{
                        route:`/academic/consultExams`,
                        method:'POST',
                        identify:'consultExams',
                        data: formData
                    })
                }*/
            },
            consultGroupByTeacher(){
                let ids = this.st.personLog.sub.datos_usuario
                let formData = new FormData();
                formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                formData.append('id_institucion',`${ids.id_institucion}`)
                formData.append('id_docente',`${ids.idacademico}`)
                formData.append('rol',`${ids.rol}`)
                this.$emit('goBackend',{
                    route:`/academic/consultGroups`,
                    method:'POST',
                    identify:'consultGroups',
                    data: formData
                })
            },
            consultTypeIntentos(){
                let ids = this.st.personLog.sub.datos_usuario
                this.$emit('goBackend',{
                    route:`/academic/typeIntento`,
                    method:'GET',
                    identify:'typeIntento',
                    data: ''
                })
            },
            consultExamsByUser(){
                let ids = this.st.personLog.sub.datos_usuario
                let formData = new FormData();
                formData.append('id_usuario',`${ids.id_usuario}`)
                this.$emit('goBackend',{
                    route:`/academic/consulExamsbyId`,
                    method:'POST',
                    identify:'consulExamsbyId',
                    data: formData
                })
            }
        },
        beforeMount() {
            this.windows = []
            this.fichas = []
            if(`${this.st.personLog.sub.datos_usuario.rol}` == '1'){
                this.consultExams();
                this.returnTeachers();
                this.consultGroupByTeacher();
                this.consultTypeIntentos();
                this.consultExamsByUser();
            }else if(`${this.st.personLog.sub.datos_usuario.rol}` == '2'){
                this.consultExams();
                this.consultGroupByTeacher();
                this.consultTypeIntentos();
                this.consultExamsByUser();
                this.windows.push(
                    {
                        id:1,
                        label:'Crear horario',
                        active:true
                    },
                    {
                        id:2,
                        label:'Ver horario',
                        active:false
                    }
                )
                this.consultar({group:1})
            }else if(`${this.st.personLog.sub.datos_usuario.rol}` == '3' || `${this.st.personLog.sub.datos_usuario.rol}` === '6'){
                this.consultar({group:parseInt(this.st.personLog.sub.user.grupo)})
                this.consultExams();
                this.returnTeachers();
                this.windows.push(
                    {
                        id:1,
                        label:'Listados',
                        active:false
                    }
                )
            }
        },
    }
</script>