<template>
    <div class="col-sm-12" style="">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: calc(100vh - 218px);background: white;">
            <div class="col-sm-12 sparkline12-list">
                <div class="sparkline12-hd col-sm-12" style="padding:0">
                    <template v-if="`${st.personLog.sub.datos_usuario.rol}` === '3'">
                        <Windows
                            :data="windows"
                            @clickWindow="''"
                        />
                        <Horarios
                            v-if="windows[0].active"
                            :st="st"
                            :dataT="datos"
                            @buttonActions="''"
                            :selectGroup="false"
                            :selectStudent="false"
                            @sendGroup="consultar"
                        />
                    </template>
                    <template v-else>
                        <Windows
                            :data="windows"
                            @clickWindow="clickWindow"
                        />
                        <CreateHorario
                            v-if="windows[0].active"
                            :st="st"
                            @buttonActions="buttonActionsForm"
                            :dataHorario="dataHorario"
                            :loadRegister="loadRegister"
                            :docents="data_teachers"
                        />
                        <Horarios
                            v-if="windows[1].active"
                            :st="st"
                            :dataT="datos"
                            @buttonActions="buttonActionsTable"
                            :selectGroup="true"
                            @sendGroup="consultar"
                            :selectStudent="false"
                            :delete_h="delete_h"
                            @changeDeleteh="delete_h = false"
                            :groups="groups"
                        />
                    </template>
                    <!--<consultHorario
                        v-if="windows[1].active"
                        @consultar="consultar"
                        :horario="data_horario"
                        @edit="editHorario"
                        @delete="deleteHorario"
                    />
                    <GestionMasiva
                        v-if="windows[2].active"
                        title="Cargar todos los docentes"
                        :data="gestionMas"
                        @buttonActions="buttonActions"
                    />-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CreateHorario from '@/views/asignments/components/createHorario.vue'
import Horarios from '@/components/Scheduler/horarios.vue'
import Windows from '@/components/Scheduler/Windows.vue'
import consultHorario from '@/views/asignments/components/consultHorario.vue'
import GestionMasiva from '@/components/Scheduler/GestionMasiva.vue'

export default{
    props:['st','outPutBackend'],
    data(){
        return{
            loadRegister:false,
            windows:[
                {
                    id:1,
                    label:'Gestión',
                    active:true
                },
                {
                    id:2,
                    label:'Listados',
                    active:false
                },
                /*{
                    id:3,
                    label:'Gestión masiva',
                    active:false
                }*/
            ],
            data_teachers:[],
            data_horario:[],
            dataHorario:{},
            gestionMas:[
                {
                    label: 'NO. IDENTIFICACIÓN',
                    content: 'Cédula de ciudadania del docente'
                },
                {
                    label: 'NOMBRES',
                    content: 'Nombres del docente, sin apellidos'
                },
                {
                    label: 'APELLIDOS',
                    content: 'Apellidos del docente'
                },
                {
                    label: 'FECHA DE NACIMIENTO',
                    content: 'La fecha de nacimiento del docente en el formato: AAAA-MM-DD (1977-07-13)'
                },
                {
                    label: 'GENERO',
                    content: 'F Si es maestra, M Si es profesor, I Si es indefinido'
                },
                {
                    label: 'TITULO O PROFESIÓN',
                    content: 'Titulo del docente'
                },
                {
                    label: 'DIRECCIÓN',
                    content: 'Dirección de domicilio del docente'
                },
                {
                    label: 'TELÉFONO',
                    content: 'Teléfono de contacto principal del docente'
                },
                {
                    label: 'MAIL',
                    content: 'Correo electrónico del docente'
                },
            ],
            datos:{
                head:['Hora','Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'],
                width:[80,'',300,300,300,300,300,300],
                rows:[]
            },
            group_act:'',
            delete_h:false
        }
    },
    components:{
        CreateHorario,
        Windows,
        consultHorario,
        GestionMasiva,
        Horarios
    },
    watch:{
        outPutBackend:{
            handler(){
                let response = this.outPutBackend
                switch(response.identify){
                    case 'new_horario':
                        //console.log(response);

                        this.consultar({group:this.group_act ? this.group_act : 1})
                        this.loadRegister = false

                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Registro exitoso',
                                text: `Se registro correctamente el nuevo horario`,
                            }
                        );
                    break;
                    case 'updateAsignments':
                        console.log('edit_user',response)

                        this.consultar({group:this.group_act ? this.group_act : 1})

                        this.loadRegister = false

                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Registro exitoso',
                                text: `Se actualizó correctamente el nuevo horario`,
                            }
                        );
                    break;
                    case 'delete_horario':
                        //this.returnTeachers();

                        this.delete_h = true

                        this.consultar({group:this.group_act ? this.group_act : 1})

                        this.$swal(
                            {
                                icon: 'success',
                                title: 'Eliminación con exito',
                                text: `Se edito eliminó el horario correctamente`,
                            }
                        );
                    break;
                    case 'select_horario':
                        //console.log('response',response)
                        this.datos.rows = []
                        let arr = []
                        let ids = Date.now();
                        for(const i in response.data.sub){
                            arr.push({
                                0:`${response.data.sub[i].row_to_json.aeasignaciones_hora}_${ids+i}`
                            })
                        }

                        for(const u in arr){
                            for(const i in response.data.sub){
                                if(`${response.data.sub[i].row_to_json.aeasignaciones_hora}_${ids+u}` === `${arr[u][0]}`){
                                    for(const s in response.data.sub[i].row_to_json.detalles){
                                        arr[u][response.data.sub[i].row_to_json.detalles[s].aeasignaciones_dia] = {
                                            id:response.data.sub[i].row_to_json.detalles[s].aeasignaciones_id,
                                            id_docente:response.data.sub[i].row_to_json.detalles[s].aedocentes_id,
                                            asignatura:response.data.sub[i].row_to_json.detalles[s].aeasignaciones_asignatura,
                                            enlace:response.data.sub[i].row_to_json.detalles[s].aeasignaciones_enlace,
                                            docente:response.data.sub[i].row_to_json.detalles[s].docente
                                        }
                                    }
                                }
                            }
                        }

                        //console.log(arr)

                        this.datos.rows = arr

                        let arr2 = JSON.parse(JSON.stringify(this.datos))
                        this.datos = {}
                        this.datos = arr2

                        if(`${this.st.personLog.sub.datos_usuario.rol}` == '3'){
                            this.windows[0].active = true
                        }
                    break;
                    case 'allteachers':
                        this.data_teachers = []
                        for(const i in response.data.sub){
                            this.data_teachers.push(
                                {
                                    id:response.data.sub[i].aedocentes_id,
                                    name:response.data.sub[i].aedocentes_nombres,
                                    last_name:response.data.sub[i].aedocentes_apellidos,
                                    identification:response.data.sub[i].aeusu_llave,
                                    phone:response.data.sub[i].aedocentes_telefono,
                                    email:response.data.sub[i].aedocentes_mail,
                                    date_birth:response.data.sub[i].aedocentes_fechanacimiento,
                                    gender:response.data.sub[i].aedocentes_genero,
                                    direction:response.data.sub[i].aedocentes_direccion
                                }
                            )
                        }
                    break;
                    case 'consultGroups':
                        console.log(response)
                        this.groups = []
                        this.groups = response.data.sub
                    break;
                }
            }
        }
    },
    methods:{
        clickWindow(data){
            for(const i in this.windows){
                if(`${data.data.id}` === `${this.windows[i].id}`){
                    this.windows[i].active = true
                }else{
                    this.windows[i].active = false
                }
            }

            if(data.data.id === 1){
                this.loadRegister = false
            }

            if(this.windows[1].active){
                //this.consultar({group:1})
            }
        },
        buttonActionsForm(caso,dataComponent){
            switch(caso){
                case 'save_horario':
                    let formDataNewHorario = new FormData();
                    formDataNewHorario.append('institucion_id',dataComponent.data.institucion_id)
                    formDataNewHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                    formDataNewHorario.append('teacher',dataComponent.data.teacher)
                    formDataNewHorario.append('group',dataComponent.data.group)
                    formDataNewHorario.append('day',dataComponent.data.day)
                    formDataNewHorario.append('asignments',dataComponent.data.asignments)
                    formDataNewHorario.append('time',dataComponent.data.time)
                    formDataNewHorario.append('link',dataComponent.data.link)

                    this.$emit('goBackend',{
                        route:'/asignments/registro',
                        method:'POST',
                        identify:'new_horario',
                        data: formDataNewHorario
                    })

                    this.loadRegister = true
                break;
                case 'edit_horario':
                    //console.log(dataComponent)
                    let formDataUptHorario = new FormData();
                    formDataUptHorario.append('institucion_id',dataComponent.data.institucion_id)
                    formDataUptHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                    formDataUptHorario.append('id',dataComponent.data.id)
                    formDataUptHorario.append('teacher',dataComponent.data.teacher)
                    formDataUptHorario.append('group',dataComponent.data.group)
                    formDataUptHorario.append('day',dataComponent.data.day)
                    formDataUptHorario.append('asignments',dataComponent.data.asignments)
                    formDataUptHorario.append('time',dataComponent.data.time)
                    formDataUptHorario.append('link',dataComponent.data.link)

                    this.$emit('goBackend',{
                        route:'/asignments/updateAsignments',
                        method:'POST',
                        identify:'updateAsignments',
                        data: formDataUptHorario
                    })

                    this.loadRegister = true
                break;
                case 'uploadCsv':
                    console.log(dataComponent.data)
                break;
            }
        },
        buttonActionsTable(caso,dataComponent){
            switch(caso){
                case 'save_horario':
                   
                    let formDataNewHorario = new FormData();
                    formDataNewHorario.append('institucion_id',dataComponent.data.institucion_id)
                    formDataNewHorario.append('ano_lectivo',dataComponent.data.ano_lectivo)
                    formDataNewHorario.append('teacher',dataComponent.teacher)
                    formDataNewHorario.append('group',dataComponent.data.group)
                    formDataNewHorario.append('day',dataComponent.data.day)
                    formDataNewHorario.append('asignments',dataComponent.data.asignments)
                    formDataNewHorario.append('time',dataComponent.data.time)
                    formDataNewHorario.append('link',dataComponent.data.link)

                    this.$emit('goBackend',{
                        route:'/asignments/registro',
                        method:'POST',
                        identify:'new_horario',
                        data: formDataNewHorario
                    })

                    this.loadRegister = true
                break;
                case 'edit_horario':
                    this.windows[0].active = true
                    this.windows[1].active = false
                    //this.windows[2].active = false
                    this.dataHorario = dataComponent
                break;
                case 'delete_horario':
                    //console.log(dataComponent)
                    this.$swal({
                        title: '¿Estás seguro/a de eliminar este horario?',
                        text: "Una vez eliminado no podra recuperar el horario",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, Eliminar',
                        cancelButtonText: 'Cancelar'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            console.log(dataComponent)
                            let formDataDelete = new FormData();
                            formDataDelete.append('id',parseInt(dataComponent.data.id));
                            this.$emit('goBackend',{
                                route:'/asignments/deleteHorario',
                                method:'POST',
                                identify:'delete_horario',
                                data: formDataDelete
                            })
                        }
                    })
                break;
                case 'uploadCsv':
                    console.log(dataComponent.data)
                break;
            }
        },
        returnTeachers(){
            let formData = new FormData();
            formData.append('id_institucion',`${this.st.personLog.sub.datos_usuario.id_institucion}`)
            formData.append('id_usu',`${this.st.personLog.sub.datos_usuario.id_usuario}`)
            formData.append('ano_lectivo',`${this.st.personLog.sub.datos_usuario.id_anolectivo}`)
            formData.append('rol',`${this.st.personLog.sub.datos_usuario.rol}`)
            this.instituto_rol = true
            this.$emit('goBackend',{
                route:'/teachers/allteachers',
                method:'POST',
                identify:'allteachers',
                data: formData
            })
        },
        consultar(data){
            this.datos.rows = []
            let ids = this.st.personLog.sub.datos_usuario;

            this.group_act = data.group
            //EL GRUPO PARA ESTUDIANTES DEBE SALIR DE LA SESION O STATE

            console.log('Esta es EL GRUPO NULL ', ids.grupo)
            let elgrupo = (ids.grupo != "" && ids.grupo != undefined)? ids.grupo : data.group ;
            console.log('Esta es EL GRUPO preif ', elgrupo)
            //CUANDO ES ADMIN O DOCENTE, EL GRUPO VIENE DESDE LA LISTA
            if(ids.rol == 1 || ids.rol == 2){
                let elgrupo = (ids.grupo != "" && ids.grupo != undefined)? ids.grupo : data.group ;
                console.log('Esta es EL GRUPO into if ', elgrupo)
            }
            console.log('Esta es EL GRUPO postif ', elgrupo)

            console.log(data)
            this.group_act = data.group
            let formData = new FormData();
            formData.append('group', elgrupo)
            formData.append('id',`${this.st.personLog.sub.datos_usuario.idacademico}`)
            formData.append('ano_lectivo',`${ids.id_anolectivo}`)
            formData.append('id_institucion',`${ids.id_institucion}`)

            let ruta = `${this.st.personLog.sub.datos_usuario.rol}` == '1' ? 'horario' : `${this.st.personLog.sub.datos_usuario.rol}` == '2' ? 'horarioTeachers' : 'horario'
            this.$emit('goBackend',{
                route:`/asignments/${ruta}`,
                method:'POST',
                identify:'select_horario',
                data: formData
            })
        },
        consultGroupByTeacher(){
            let ids = this.st.personLog.sub.datos_usuario
            let formData = new FormData();

            formData.append('ano_lectivo',`${ids.id_anolectivo}`)
            formData.append('id_institucion',`${ids.id_institucion}`)
            formData.append('id_docente',`${ids.idacademico}`)
            formData.append('rol',`${ids.rol}`)

            this.$emit('goBackend',{
                route:`/academic/consultGroups`,
                method:'POST',
                identify:'consultGroups',
                data: formData
            })
        }
    },
    beforeMount(){
        //this.returnTeachers();
        
        this.windows = []
        if(`${this.st.personLog.sub.datos_usuario.rol}` == '1'){
            this.windows.push(
                {
                    id:1,
                    label:'Crear horario',
                    active:true
                },
                {
                    id:2,
                    label:'Ver horario',
                    active:false
                }
            )
            this.consultar({group:1})
            this.returnTeachers();
            this.consultGroupByTeacher();
        }else if(`${this.st.personLog.sub.datos_usuario.rol}` == '2'){
            
            this.windows.push(
                {
                    id:1,
                    label:'Gestión',
                    active:true
                },
                {
                    id:2,
                    label:'Listados',
                    active:false
                }
            )
            this.returnTeachers();
            this.consultar({group:1})
            this.consultGroupByTeacher();
        }else if(`${this.st.personLog.sub.datos_usuario.rol}` == '3'){
            this.windows.push(
                {
                    id:1,
                    label:'Listados',
                    active:false
                }
            )
            this.consultar({group:1})
        }
    }
}
</script>
