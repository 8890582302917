<template>
    <div>
        <Header
            @reduceMenu="reduceMenu = !reduceMenu"
            :st="st"
            @openMenuMb="active_menu_mb = !active_menu_mb"
        />
        <Nav
            :menuReduce="reduceMenu"
            :st="st"
            @routePushing="routePushing"
            :menuReducemb="active_menu_mb"
            @closeMenu="active_menu_mb = false"
        />
        <div ref="content_router_view" :class="`col-md-12 content_router_view ${reduceMenu ? 'menu_reduce' : ''}`" >
            <div style="height:100%;width:100%;">
                <Navigator/>
                <router-view
                    :st="st"
                    @goBackend="goBackend"
                    :outPutBackend="output_backend"
                />
            </div>

            <div v-if="show_but" class="no_sele" style="cursor:pointer;border-radius:5px;border:2px solid purple;height:40px;width:40px;position:fixed;bottom:15px;right:25px;background:white" @click="scrollTop()">
                <i class="material-icons" style="font-size:35px">expand_less</i>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
    import Vuex from '@/store'
    import Nav from '@/components/Scheduler/Nav.vue';
    import Header from '@/components/Scheduler/Header.vue';
    import Navigator from '@/components/Scheduler/Navigator.vue';
    import jwt_decode from "jwt-decode";
    export default {
        props:['st'],
        data(){
            return{
                active_menu_mb:false,
                reduceMenu:false,
                show_but:false,
                output_backend:{}
            }
        },
        components:{
            Nav,
            Header,
            Navigator
        },
        methods:{
            routePushing(data){
                Vuex.dispatch('goToModule',{route:data.route})
            },
            async goBackend(data){
                let response = await Vuex.dispatch('goToBackend',data)
                this.output_backend = {
                    status:response.status,
                    identify:response.identify,
                    data:jwt_decode(response.data)
                }
            },
            scrollTop(){
                let scroll = document.getElementById('scroll_all');

                scroll.scrollTop = 0
            }
        },
        beforeMount(){
            this.$nextTick(()=>{

                let scroll = document.getElementById('scroll_all');

                scroll.addEventListener('scroll', ()=>{
                    if(scroll.scrollTop == 0){
                        this.show_but = false
                    }else{
                        this.show_but = true
                    }
                })
                //this.$refs['content_router_view'].scrollTop = 20
            })
        }
    }
</script>