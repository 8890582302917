<template>
    <div class="col-md-12" style="position:absolute;top:50%;transform: translate(0,-50%);">
        <div class="text-center m-b-md custom-login" style="margin-bottom:10px">
            <img src="@/assets/images/logo/logo.png" alt="">
        </div>
        <div class="col-md-12">
            <form v-on:submit.prevent="exist_email">
                <div class="form-group">
                    <label class="control-label" for="username">Email o usuario</label>
                    <input type="text" placeholder="Email o usuario registrado" class="form-control" v-model="username">
                </div>
                <button @click="exist_email" class="btn btn-primary btn-block loginbtn">Restablecer contraseña</button>
            </form>
        </div>
        <div class="col-md-12" style="margin-top:20px">
            <div class="col-md-12 text-center" style="margin-bottom:30px" @click="$emit('volverLogin')">
                <span class="login_span_term no_sele">Iniciar sesión</span>
            </div>
        </div>
    </div>
</template>
<script>
    import Vuex from '@/store'
    export default {
        data(){
            return{
                username: ''
            }
        },
        methods:{
            async exist_email(){
                let response = await Vuex.dispatch('goToBackend',{
                    route:`/users/resetpass?type=0&who=${this.username}`,
                    method:'POST'
                })
                console.log(response)
                /*this.$swal(
                    {
                        icon: 'warning',
                        title: 'Correo enviado con exito',
                        text: `Se ha enviado un email a tu correo con los pasos para recuperar tu contraseña`,
                    }
                );
                this.$swal(
                    {
                        icon: 'error',
                        title: 'Usuario no encontrado',
                        text: `El usuario no se encuentra registrado`,
                    }
                );*/
            }
        }
    }
</script>