<template>
  <div id="app">
    <div @scroll="scroll_to($event)" id="scroll_all" class="col-md-12">
      <Login v-if="!session" :st="state"/>
      <Scheduler v-else-if="session" :st="state"/>
    </div>
  </div>
</template>

<script>
  import Login from '@/components/Login/Login.vue';
  import Scheduler from '@/components/Structure.vue'
  import 'material-design-icons-iconfont';
  import 'vue-select/dist/vue-select.css';
  import 'vue2-datepicker/index.css';
  import Vuex from '@/store'
  const state = Vuex.state;
  export default {
    data(){
      return{
        state:state
      }
    },
    components:{
      Login,
      Scheduler
    },
    computed:{
      session(){
        return this.state.session;
      }
    },
    methods:{
      scroll_to(e){
        state.scroll_to = {
          top: e.target.scrollTop,
          left: e.target.scrollLeft,
          height: e.target.scrollHeight
        }
      },
      sizeWindows(){
        var w = window.innerWidth;
        var h = window.innerHeight;
        state.windowSizes.width = w;
        state.windowSizes.height = h;
      }
    },
    beforeMount(){
      var th = this;
      th.sizeWindows();
      window.addEventListener("resize", ()=>{
        th.sizeWindows();
      });
      if(this.$session.has('user')){
        this.state.session = true;
        this.state.personLog = this.$session.get('user');
        this.state.token = localStorage.getItem('token');
      }else{
        this.state.session = false;
        this.state.personLog = {};
      }
    }
  }
</script>

<style>
#scroll_all{
  padding:0 !important;
  overflow:hidden;
  overflow-y:scroll;
  height:100vh;
  /*padding-top: 50px !important;*/
  /*padding-bottom: 40px !important;*/
  position: relative;
  scroll-behavior: smooth;
}
</style>
