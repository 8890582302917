<template>
    <div class="col-sm-12" style="margin-top:15px">
        <template v-if="`${st.personLog.sub.datos_usuario.rol}` === '1' || `${st.personLog.sub.datos_usuario.rol}` === '2'">
            <div class="col-sm-12" style="padding:0 0 20px 0">
                <!--<CreateQuestions
                    :st="st"
                    @createExam="createExam"
                />-->
                <CreateExam
                    :st="st"
                    @createExam="createExam"
                    :groups="groups"
                    :typeIntentos="typeIntentos"
                    :loadRegister="loadRegister"
                    :dataExams="dataExams"
                    @createQuestions="createQuestions"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import CreateExam from '@/components/Exams/CreateExam.vue'
    export default {
        props:['st','outPutBackend'],
        data(){
            return{
                loadRegister:false,
                groups:[],
                typeIntentos:[],
                dataExams:[]
            }
        },
        components:{
            CreateExam
        },
        watch:{
            outPutBackend:{
                handler(){
                    let response = this.outPutBackend
                    switch(response.identify){
                        case 'consultGroups':
                            //console.log(response)
                            this.groups = []
                            this.groups = response.data.sub
                        break;
                        case 'create_exam':
                            //console.log('its ok',response)

                            this.loadRegister = false
                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Registro exitoso',
                                    text: `Se registro correctamente el nuevo examen`,
                                }
                            );

                            this.consultExamsByUser();
                        break;
                        case 'typeIntento':
                            this.typeIntentos = []
                            this.typeIntentos = response.data.sub
                            //console.log(response)
                        break;
                        case 'consulExamsbyId':
                            this.dataExams = []
                            this.dataExams = response.data.sub
                        break;
                        case 'create_question':
                            this.$swal(
                                {
                                    icon: 'success',
                                    title: 'Registro exitoso',
                                    text: `Se registro correctamente una pregunta nueva`,
                                }
                            );
                        break;
                    }
                }
            }
        },
        methods: {
            createQuestions(data){

                let formData = new FormData();
                formData.append('id_exam',data.id_exam);
                formData.append('description',data.body.content);
                formData.append('order',data.body.questions_order_selected);
                formData.append('type_question',data.body.type_question.code);
                formData.append('options',JSON.stringify(data.options));

                formData.append('id_institucion',`${this.st.personLog.sub.datos_usuario.id_institucion}`)
                formData.append('id_usu',`${this.st.personLog.sub.datos_usuario.id_usuario}`)
                formData.append('ano_lectivo',`${this.st.personLog.sub.datos_usuario.id_anolectivo}`)
                formData.append('rol',`${this.st.personLog.sub.datos_usuario.rol}`)

                this.$emit('goBackend',{
                    route:'/academic/add_questions',
                    method:'POST',
                    identify:'create_question',
                    data: formData
                })
            },
            createExam(data){
                let groups = [];
                for(const i in data.selected_group){
                    groups.push(data.selected_group[i].code)
                }
                let ids = this.st.personLog.sub.datos_usuario
                let formData = new FormData();
                formData.append('content',data.contenido);
                formData.append('date_init',data.date_init)
                formData.append('date_finish',data.date_finish)
                formData.append('time',data.time)
                formData.append('name_exam',data.name_exam)
                formData.append('file',data.file)
                formData.append('intentos',data.num_try)
                formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                formData.append('id_institucion',`${ids.id_institucion}`)
                formData.append('id_usuario',`${ids.id_usuario}`)
                formData.append('groups',JSON.stringify(groups))
                formData.append('tipo_intento',parseInt(data.selected_typeIntentos.code))
                formData.append('ordenado',data.questions_order_selected.code)
                this.$emit('goBackend',{
                    route:'/academic/addExam',
                    method:'POST',
                    identify:'create_exam',
                    data: formData
                })

                this.loadRegister = true
            },
            consultGroupByTeacher(){
                let ids = this.st.personLog.sub.datos_usuario
                let formData = new FormData();

                formData.append('ano_lectivo',`${ids.id_anolectivo}`)
                formData.append('id_institucion',`${ids.id_institucion}`)
                formData.append('id_docente',`${ids.idacademico}`)
                formData.append('rol',`${ids.rol}`)

                this.$emit('goBackend',{
                    route:`/academic/consultGroups`,
                    method:'POST',
                    identify:'consultGroups',
                    data: formData
                })
            },
            consultTypeIntentos(){
                let ids = this.st.personLog.sub.datos_usuario

                this.$emit('goBackend',{
                    route:`/academic/typeIntento`,
                    method:'GET',
                    identify:'typeIntento',
                    data: ''
                })
            },
            consultExamsByUser(){
                let ids = this.st.personLog.sub.datos_usuario
                let formData = new FormData();

                formData.append('id_usuario',`${ids.id_usuario}`)

                this.$emit('goBackend',{
                    route:`/academic/consulExamsbyId`,
                    method:'POST',
                    identify:'consulExamsbyId',
                    data: formData
                })
            }
        },
        beforeMount() {
            this.consultExamsByUser();
            this.consultGroupByTeacher();
            this.consultTypeIntentos();
        },
    }
</script>